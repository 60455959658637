import { BrainResearch, PlusCircle, Upload } from 'iconoir-react';
import { Link } from 'react-router-dom';

import "./AssessmentItem.css"
import useCallApi from "src/api/useCallApi";
import { protectedResources } from 'src/auth/AuthConfig';
import { useNavigate } from 'react-router-dom';
import { Tab } from 'src/api/goodpoint/Const';
import { StepState } from 'src/api/goodpoint/Const';
import dayjs from 'dayjs';
import GoodPointApi from 'src/api/goodpoint/GoodPointApi';
import { TabIcons } from 'src/pages/goodpoint/assessment/tabs/Tabs';
import IconButton from 'src/components/ui/IconButton';

function nextStepIcon(assessmentObject) {
    if (assessmentObject.configure_step < StepState.COMPLETED) return TabIcons[Tab.INFO];
    if (assessmentObject.rubric_step === StepState.AVAILABLE) return Upload;
    if (assessmentObject.rubric_step < StepState.COMPLETED) return TabIcons[Tab.RUBRIC];
    if (assessmentObject.student_answers_step === StepState.AVAILABLE) return Upload;
    if (assessmentObject.student_answers_step < StepState.COMPLETED) return TabIcons[Tab.ANSWERS];
    if (assessmentObject.grading_step < StepState.COMPLETED) return BrainResearch;
    return TabIcons[Tab.GRADING];
}

function nextStep(assessmentObject) {
    switch (assessmentObject.grading_step) {
        case StepState.COMPLETED: return "Graded"
        case StepState.PROCESSING: return "Grading..."
        case StepState.READY:
        case StepState.AVAILABLE: return "Ready to grade"
        default:
    }
    switch (assessmentObject.student_answers_step) {
        case StepState.PROCESSING: return "Processing student answers..."
        case StepState.READY: return "Process student answers"
        case StepState.AVAILABLE: return "Upload student answers"
        default:
    }
    // Let the user see the exam configure step at least once first
    switch (assessmentObject.configure_step) {
        case StepState.READY: return "Configure exam"
        default:
    }
    switch (assessmentObject.rubric_step) {
        case StepState.PROCESSING: return "Processing rubric..."
        case StepState.READY: return "Process rubric"
        case StepState.AVAILABLE: return "Upload rubric"
        default:
    }
}

export function NewAssessment() {
    const callApi = useCallApi({
        msalRequest: {scopes: protectedResources.apiGoodPoint.scopes.write}
    })
    const navigate = useNavigate();

    function createAssessment() {
        callApi("POST", GoodPointApi.NewAssessment)?.then(response => {
            if (response.status === 200) {
                response.json().then(body => {
                    navigate(`assessment/${body["id"]}`);
                });
            } else {
                console.error("Failed to create new assessment: " + response.status + " " + response.statusText);
            }
        });
    }
    
    return <IconButton
        text={"New Assessment"}
        fontSize='large'
        Icon={PlusCircle}
        iconSize={25}
        onClick={createAssessment}
        justifyContent='start'
        paddingSize='15px'
        gapSize='var(--gap-mid)'
        className={`fill-width`}
    />
}

export function AssessmentItem({ item, clickable=true }) {
    const bgColour = item.grading_step === StepState.COMPLETED ? "bg-grey-light" : "bg-yellow-light";
    const date = item.date ? dayjs(item.date).format('DD MMM YYYY') : "";
    const ActionIcon = nextStepIcon(item);
    return (
        <Link 
            className={
                `assessment-item ${clickable ? "clickable" : "cursor-disabled"} ${bgColour} ` +
                `w-full padding-large border-mid rounded-small `
            }
            to={`assessment/${item.id}`}
        >
            <div className={`assessment-item-grid gap-large box-sizing-border-box`}>
                <p className='assessment-title'>
                    <b>{item.name}</b>
                </p>
                <p className='assessment-date'>
                    {date}
                </p>
                <p className='assessment-course-title'>
                    {item.course_title !== "(course title)" ? item.course_title : null}
                </p>
                <p className='assessment-subject'>
                    {item.subject !== "(subject)" ? item.subject : null}
                </p>
                <div className='assessment-action flex-row gap-mid justify-content-right'>
                    {nextStep(item)}
                    <ActionIcon />
                </div>
            </div>
        </Link>
    )
}