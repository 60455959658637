import React, { useState, useEffect, useRef } from "react";
import PopupMathField from "./PopupMathField";
import {
  TrashSolid,
  SigmaFunction,
  UndoCircleSolid,
  RedoCircle,
} from "iconoir-react";
import { ReactComponent as LatexSvg } from "./latex.svg";
import "./Scrollbar.css";
import { tokenizeText, unTokenizeText } from "./Helpers";
import Latex from "react-latex-next";
//latex editor
import "prismjs";
import "prismjs/themes/prism-coy.css";
import "prismjs/components/prism-latex.min.js";
import Prism from "prismjs";
import Editor from "react-simple-code-editor";
import { highlight } from "prismjs/components/prism-core";
import AreYouSureModal from "./AreYouSureModal";
import RenderMath from "./RenderMath";

function LatexIcon() {
  return <LatexSvg width="50px" height="30px" viewBox="20 17 25 30" />;
}

function TextIcon() {
  return (
    <svg
      width="24px"
      height="24px"
      strokeWidth="1.5"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color="#000000"
    >
      <path
        d="M19 7V5L5 5V7"
        stroke="#000000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M12 5L12 19M12 19H10M12 19H14"
        stroke="#000000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
}

const EditorStates = {
  TEXT: "text",
  LATEX: "latex",
};

function Tab({ active = false, onMouseDown, children, className = "" }) {
  const classNames = `mr-2 inline-block cursor-pointer p-1 hover:bg-slate-100 active:bg-slate-200 ${active ? "bg-slate-300 hover:bg-slate-300" : ""
    }  ${className}`;
  return (
    <div onMouseDown={onMouseDown} className={classNames}>
      {children}
    </div>
  );
}

const RichMathEditor = ({ initialTokens, saveContent, closeEditor }) => {
  //tokens determines what's being displayed in text editor
  // const [tokens, setTokens] = useState(tokenizeText(initialContent));
  const [tokens, setTokens] = useState(initialTokens);
  //content determines what's being displayed in latex editor
  const content = unTokenizeText(tokens);

  //keep history of states for undo/redo
  const [historyTokens, setHistoryTokens] = useState([
    // tokenizeText(initialContent),
    initialTokens
  ]);
  const [historyIndex, setHistoryIndex] = useState(0); //which tokens state am i at right now

  //how the popupmathfield looks and works
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentLatexId, setCurrentLatexId] = useState(null);
  const [left, setLeft] = useState("");
  const [right, setRight] = useState("");

  //which editor is being displayed (default = text)
  const [editor, setEditor] = useState(EditorStates.TEXT);

  //popup modals for saving and discarding changes
  const [savePopupOpen, setSavePopupOpen] = useState(false);
  const [discardPopupOpen, setDiscardPopupOpen] = useState(false);

  //effect for focus caret on last letter
  const lastEditableRef = useRef(null);
  useEffect(() => {
    if (lastEditableRef.current) {
      lastEditableRef.current.focus();

      // Move caret to the end of the content
      const range = document.createRange();
      const selection = window.getSelection();
      range.selectNodeContents(lastEditableRef.current);
      range.collapse(false); // Move the caret to the end
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }, []);

  //add a new state of tokens to historyTokens
  const addNewHistory = ({ updatedTokens }) => {
    const removeDuplicates = (tokens) => {
      const seen = new Set();
      return tokens.filter((token) => {
        const key = JSON.stringify(token); // Use JSON.stringify to handle complex objects
        return seen.has(key) ? false : seen.add(key);
      });
    };

    // Update historyTokens to include the new tokens
    setHistoryTokens((prevTokens) => {
      // Add the new tokens to the history
      const newHistory = [
        ...prevTokens.slice(0, historyIndex + 1), // Keep tokens up to the current historyIndex
        updatedTokens, // Add the new tokens
      ];

      // Remove duplicates while preserving the most recent state
      return removeDuplicates(newHistory);
    });
  };

  //update the index that i'm at in historyTokens whenver historyTokens updates
  useEffect(() => {
    // Ensure historyIndex is updated only if it is within bounds
    setHistoryIndex((prevIndex) => {
      const newIndex = prevIndex + 1;
      if (newIndex < historyTokens.length) {
        return newIndex;
      }
      return prevIndex; // Return the current index if it exceeds the length
    });
  }, [historyTokens]);

  //update text whenever users type something
  const handleTextChange = (e, id, reTokenize = false) => {
    const newText = e.target.innerText;

    if (newText.trim() === "") {
      deleteToken(id);
    } else {
      let updatedTokens = tokens.map((token) => {
        if (token.id === id) {
          return { ...token, content: newText };
        }
        return token;
      });
      setTokens(updatedTokens);
      const removeDuplicates = (tokens) => {
        const seen = new Set();
        return tokens.filter((token) => {
          const key = JSON.stringify(token); // Use JSON.stringify to handle complex objects
          return seen.has(key) ? false : seen.add(key);
        });
      };

      // Update historyTokens to include the new tokens
      setHistoryTokens((prevTokens) => {
        // Add the new tokens to the history
        const newHistory = [
          ...prevTokens.slice(0, historyIndex + 1), // Keep tokens up to the current historyIndex
          updatedTokens, // Add the new tokens
        ];

        // Remove duplicates while preserving the most recent state
        return removeDuplicates(newHistory);
      });
    }
  };

  //open popupmathfield whenever users click on existing equation
  const handleLatexClick = (id) => {
    setCurrentLatexId(id);
    setIsModalOpen(true);
  };

  //stuff for adding new equations
  const getCaretDetails = () => {
    let caretIndex = -1; // no caret
    let caretOffset = 0; // Default caret offset
    let leftString = ""; // Initialize left part of the split
    let rightString = "";

    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const parentNode = range.startContainer.parentNode;

      if (parentNode && parentNode.getAttribute("contenteditable") === "true") {
        const textContent = parentNode.textContent;
        caretOffset = range.startOffset; // Caret offset within the text

        leftString = textContent.slice(0, caretOffset);
        rightString = textContent.slice(caretOffset);

        // Find the token index
        for (let index = 0; index < tokens.length; index++) {
          const token = tokens[index];
          if (parentNode.textContent.trim() === token.content.trim()) {
            caretIndex = index;
            break;
          }
        }
      }
    }

    return { caretIndex, leftString, rightString };
  };
  const handleMathIconClick = (e) => {
    e.preventDefault(); // Prevent the focus from shifting
    e.stopPropagation();

    const { caretIndex, leftString, rightString } = getCaretDetails();

    setLeft(leftString);
    setRight(rightString);
    setCurrentLatexId(caretIndex);

    setIsModalOpen(true);
  };

  //delete token
  const deleteToken = (tokenId) => {
    let updatedTokens = [...tokens];
    const beforeToken = tokens[tokenId - 1];
    const afterToken = tokens[tokenId + 1];

    //merge the text of the tokens before and after if they are both texts
    if (
      beforeToken &&
      afterToken &&
      beforeToken.type === "text" &&
      afterToken.type === "text"
    ) {
      const mergedToken = {
        ...beforeToken,
        content: beforeToken.content + afterToken.content,
      };

      updatedTokens[tokenId - 1] = mergedToken;
      updatedTokens = updatedTokens.filter((token) => token.id !== tokenId + 1);
    }

    // Filter out the token that's being deleted
    updatedTokens = updatedTokens.filter((token) => token.id !== tokenId);

    // Update the IDs of the remaining tokens to be consecutive
    const reIndexedTokens = updatedTokens.map((token, index) => ({
      ...token,
      id: index,
    }));

    // Update the state with the new tokens array
    setTokens(reIndexedTokens);
    const removeDuplicates = (tokens) => {
      const seen = new Set();
      return tokens.filter((token) => {
        const key = JSON.stringify(token); // Use JSON.stringify to handle complex objects
        return seen.has(key) ? false : seen.add(key);
      });
    };

    // Update historyTokens to include the new tokens
    setHistoryTokens((prevTokens) => {
      // Add the new tokens to the history
      const newHistory = [
        ...prevTokens.slice(0, historyIndex + 1), // Keep tokens up to the current historyIndex
        reIndexedTokens, // Add the new tokens
      ];

      // Remove duplicates while preserving the most recent state
      return removeDuplicates(newHistory);
    });
  };

  const handleUndo = () => {
    setHistoryIndex((prevIndex) => {
      const newIndex = Math.max(prevIndex - 1, 0); // Ensure index is not below 0
      setTokens(historyTokens[newIndex]); // Set tokens to the previous state
      return newIndex; // Update historyIndex
    });
  };

  const handleRedo = () => {
    setHistoryIndex((prevIndex) => {
      const newIndex = Math.min(prevIndex + 1, historyTokens.length - 1); // Ensure index is within bounds
      if (newIndex !== prevIndex) {
        setTokens(historyTokens[newIndex]); // Set tokens to the next state
      }
      return newIndex; // Update historyIndex
    });
  };


  return (
    <div>
      <div className="w-full my-5 shadow-md shadow-slate-300 border-t-2 border-slate-200 overflow-auto">
        {/* top toolbar */}
        <div className="px-5 border-slate-100 border-b-2 flex flex-row shadow-sm shadow-slate-200">
          <Tab
            active={editor === EditorStates.TEXT}
            onMouseDown={() => setEditor(EditorStates.TEXT)}
          >
            <TextIcon />
          </Tab>
          <Tab
            active={editor === EditorStates.LATEX}
            onMouseDown={() => setEditor(EditorStates.LATEX)}
          >
            <LatexIcon />
          </Tab>
        </div>

        {/* bottom toolbar */}
        <div className="py-1 px-5 border-slate-100 border-b-2 flex flex-row shadow-md shadow-slate-200">
          <Tab onMouseDown={handleUndo}>
            <UndoCircleSolid />
          </Tab>
          <Tab onMouseDown={handleRedo}>
            <RedoCircle />
          </Tab>

          {editor === EditorStates.TEXT && (
            <Tab className="ml-5" onMouseDown={handleMathIconClick}>
              <SigmaFunction />
            </Tab>
          )}
        </div>

        <div className="h-auto">
          {editor === EditorStates.TEXT && (
            <div className="p-5">
              {tokens.map((token, index) => {
                if (token.type === "latex") {
                  const isBlockLatex =
                    token.content.startsWith("$$") &&
                    token.content.endsWith("$$");

                  return (
                    <div
                      onClick={() => handleLatexClick(token.id)}
                      className={`select-none group relative py-0 cursor-pointer hover:border-thin hover:border-slate-500 border border-transparent
                        ${isBlockLatex
                          ? "flex justify-center items-center"
                          : "inline-block"
                        }`}
                      key={token.id}
                    >
                      <TrashSolid
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the click from bubbling up to the parent div
                          deleteToken(token.id);
                        }}
                        className="bg-white absolute right-0 z-20 -translate-y-[120%] border-2 text-xl hover:text-2xl border-slate-300 text-slate-900 hover:text-red-500 opacity-0 group-hover:opacity-100 transition-all duration-200"
                      />

                      {token.content.includes("tilde") ? (
                        <math-field
                          onClick={() => handleLatexClick(token.id)}
                          read-only
                          style={{
                            display: isBlockLatex ? "block" : "inline-block",
                            whiteSpace: "pre-wrap",
                            textAlign: isBlockLatex ? "center" : "unset", // Center the block latex
                            margin: isBlockLatex ? "0 auto" : "0",
                            pointerEvents: "none",
                            position: "relative", // Add this line
                            zIndex: 0,
                          }}
                        >
                          {token.content}
                        </math-field>
                      ) : (
                        <Latex>{token.content}</Latex>
                      )}


                      <div
                        onClick={() => handleLatexClick(token.id)}
                        className="absolute inset-0 cursor-pointer z-10 bg-transparent"
                      />
                    </div>
                  );
                }
                return (
                  <span
                    key={token.id}
                    contentEditable
                    suppressContentEditableWarning
                    onBlur={(e) => handleTextChange(e, token.id)}
                    style={{ whiteSpace: "pre-wrap" }}
                    className="z-10 focus:outline-none"
                    ref={index === tokens.length - 1 ? lastEditableRef : null}
                  >
                    {token.content === "" ? <br /> : token.content}
                  </span>
                );
              })}
            </div>
          )}

          {editor === EditorStates.LATEX && (
            <div className="flex h-auto">
              {/* Left side: Editor */}
              <div className="w-1/2 p-5 overflow-auto border-r">
                <Editor
                  value={content}
                  textareaClassName="focus:outline-none"
                  preClassName="focus:outline-none"
                  onValueChange={(content) => {
                    setTokens(tokenizeText(content));
                    addNewHistory({ updatedTokens: tokenizeText(content) });
                  }}
                  highlight={(content) =>
                    highlight(content, Prism.languages.latex, "latex")
                  }
                />
              </div>

              {/* Right side: Preview */}
              <div className="w-1/2 p-5 overflow-auto">
                <div>
                  <RenderMath tokens={tokens} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {isModalOpen && (
        <PopupMathField
          setIsModalOpen={setIsModalOpen}
          tokenId={currentLatexId}
          tokens={tokens}
          setTokens={setTokens}
          setHistoryTokens={setHistoryTokens}
          historyIndex={historyIndex}
          left={left}
          right={right}
        />
      )}

      {/* save and cancel buttons  */}
      <div className="flex flex-row justify-end gap-large mt-3 text-md">
        <button
          className="clickable bg-orange-50 border-thin rounded-small py-1"
          onClick={() => {
            setDiscardPopupOpen(true);
          }}
        >
          Cancel
        </button>

        <button
          className="clickable bg-orange-mid border-thin rounded-small py-1"
          onClick={() => {
            setSavePopupOpen(true);
          }}
        >
          Save
        </button>
      </div>

      <AreYouSureModal
        popupOpen={savePopupOpen}
        closePopup={() => setSavePopupOpen(false)}
        title={"Save changes"}
        content={
          "Are you sure you want to save the changes?"
        }
        onConfirm={() => {
          setSavePopupOpen(false);
          saveContent({ newContent: content });
          closeEditor();
        }}
        lightButton={"Cancel"}
        darkButton={"Save changes"}
      />

      <AreYouSureModal
        popupOpen={discardPopupOpen}
        closePopup={() => setDiscardPopupOpen(false)}
        title={"Discard changes"}
        content={
          "If you discard changes, your unpublished changes will be deleted. Are you sure?"
        }
        onConfirm={() => {
          setDiscardPopupOpen(false);
          closeEditor();
        }}
        danger={true}
        lightButton={"Discard"}
        darkButton={"Keep draft"}
      />
    </div>
  );
};

export default RichMathEditor;
