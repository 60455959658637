import { useMemo, useContext } from "react";
import LoadingTab from "src/components/ui/LoadingTab";
import { AssessmentContext } from "src/pages/goodpoint/assessment/AssessmentContext";
import { ExamStructure } from "src/pages/goodpoint/assessment/components/structure/ExamStructure";
import QuestionFeedbackItem from "src/pages/goodpoint/assessment/tabs/grading/class-performance/question-feedback/QuestionFeedbackItem";


export default function QuestionFeedbackStructure({ 
    questionFeedback, 
    setQuestionFeedback,
    isFilterOnlyMistakes = false
}) {
    const numAnswers = useMemo(() => {
        return Object.keys(questionFeedback ?? {}).length;
    }, [questionFeedback]);

    function QuestionFeedbackHeader() {
        return <div className="flex-row gap-mid">
            <p>{numAnswers} Question{(numAnswers > 1) ? "s" : ""}</p>
        </div>
    }
    const {rubricUrl} = useContext(AssessmentContext);

    if (!questionFeedback) {
        return <LoadingTab />
    }

    return (
        <ExamStructure
            structureMap={questionFeedback}
            setStructure={setQuestionFeedback}
            HeaderComponent={QuestionFeedbackHeader}
            ItemComponent={QuestionFeedbackItem}
            initiallyExpanded={true}
            itemCommonProps={{isFilterOnlyMistakes}}
            fileUrl = {rubricUrl}
            listDisplay={[0, 0, 1, 1, 1, 0]}
        />
    )
}