import "./EditableAssessmentName.css"
import { useContext, useEffect, useRef, useState } from "react";
import { AssessmentContext } from "src/pages/goodpoint/assessment/AssessmentContext";
import useSendConfig from "src/api/goodpoint/useSendConfig";
import { useHasRole } from "src/api/useGetRole";


export default function EditableAssessmentName({ maxLength=60 }) {
    const disabled = useHasRole("student");
    const { assessmentObject, updateAssessmentObject } = useContext(AssessmentContext);
    const sendConfig = useSendConfig(assessmentObject.id, updateAssessmentObject);

    const [inputValue, setInputValue] = useState(assessmentObject.exam_config.name);
    const inputRef = useRef(null);

    useEffect(() => {
        if (!inputRef) return;
        inputRef.current.textContent = assessmentObject.exam_config.name;
    }, [assessmentObject.exam_config.name]);

    function onKeyDown(event) {
        if (event.keyCode !== 8 && inputRef.current.textContent.length >= maxLength) {
            event.preventDefault();
            return;
        }
    }

    function onBlur() {
        if (inputRef.current.textContent.length === 0) {
            inputRef.current.textContent = `Assessment #${assessmentObject.id}`;
        }
        setInputValue(inputRef.current.textContent);
    }

    useEffect(() => {
        if (inputValue === assessmentObject.exam_config.name) return;
        updateAssessmentObject(
            {
                exam_config: {
                    ...assessmentObject.exam_config,
                    name: inputValue
                }
            }
        );
        sendConfig({name: inputValue});
    }, [assessmentObject.exam_config, inputValue, sendConfig, updateAssessmentObject]);

    return (
        <div className={`editable-assessment-name flex-row align-center fill-width display-inline-block`}>
            <span 
                ref={inputRef}
                className={
                    `editable-assessment-name-span margin-none py-2 fill-width ` +
                    `word-wrap-break-word display-block vertical-align-middle`
                }
                contentEditable={!disabled}
                suppressContentEditableWarning
                spellCheck={true}
                onKeyDown={onKeyDown}
                onBlur={onBlur}
            />
        </div>
    );
}