
function ProductHeroSection({ title, subtitle, image, CTASection, square = false}) {
    return (
        <div className={"py-6 product-hero " + (!image && "pb-24")}>
            <div className="container mx-auto h-full">
                <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-[100px] h-full">
                    <div className="flex-col h-full justify-content-center">
                        <ProductHeadlineSection title={title} subtitle={subtitle} />
                        <CTASection />
                    </div>
                    <div className="flex-col h-full justify-content-center ml-4">
                        {   image && (
                                square ? (
                                    <div className='bg-transparent h-[500px] rounded hidden md:inline'>
                                        <img className="object-cover h-full shadow-lg rounded shadow-neutral-400 aspect-square" src={image} alt="GoodPoint" />
                                    </div>
                                ) : (
                                    <div className='w-[1200px] h-[500px] bg-transparent rounded hidden md:inline'>
                                        <img className="object-contain h-full rounded shadow-lg shadow-neutral-400" src={image} alt="GoodPoint" />
                                    </div>
                                )
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

function ProductHeadlineSection({ title, subtitle }) {
    return (
        <div className="flex-col justify-content-center items-start py-6 animate-fadeIn">
            <h1 className="py-2 text-start font-bold text-5xl afacad-flux-normal">
                {title}
            </h1>
            <p className="text-start text-3xl afacad-flux-normal">
                {subtitle}
            </p>
        </div>
    )
}

export default ProductHeroSection;