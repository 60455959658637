/* eslint-disable */
import { useState, useContext } from 'react';
import { AssessmentContext } from 'src/pages/goodpoint/assessment/AssessmentContext';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import {
    NavArrowLeft, NavArrowRight,
    Plus, Minus, ZoomIn,
    FastArrowLeft, FastArrowRight,
    AlignLeftBoxSolid, AlignRightBoxSolid,
    Xmark, DownloadSquare,
    Expand, Collapse, Link as LinkIcon, OpenNewWindow
} from 'iconoir-react';
import "./PdfViewer.css"
import { PdfViewerLoadingSkeleton, PdfViewerError } from './PdfViewerSkeleton';
import Popup from 'reactjs-popup';
import { Link } from 'react-router-dom';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/',
};

const Button = ({ disabled = null, onClick, children, className = '' }) => {
    return (
        <button
            type="button"
            disabled={disabled}
            onClick={onClick}
            className={`${!disabled ? 'hover:bg-gray-200 transition-all ease-in' : 'opacity-25'} px-1 ${className}`}
        >
            {children}
        </button>
    )
}

export default function PdfViewer({ file, fileName = "file", showToolbar = true, onClose, post = {} }) {
    // const [numPages, setNumPages] = useState(0);
    // const [pageNumber, setPageNumber] = useState(1);
    // const [scale, setScale] = useState(1.0);
    // const { rightPDF, setRightPDF } = useContext(AssessmentContext);
    const [showFullscreenPopup, setShowFullscreenPopup] = useState(false);


    // function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    //     setNumPages(nextNumPages);
    // }

    // const PageNavigator = () => {
    //     function handlePageChange(e) {
    //         const newPage = parseInt(e.target.value);
    //         if (newPage < 1 || newPage > numPages) {
    //             return
    //         } else {
    //             setPageNumber(parseInt(e.target.value))
    //         }
    //     }

    //     return (
    //         <div className="flex-row justify-center items-center ml-4">
    //             <Button
    //                 disabled={pageNumber <= 1}
    //                 onClick={() => { setPageNumber(1) }}
    //             >
    //                 <FastArrowLeft />
    //             </Button>

    //             <Button
    //                 disabled={pageNumber <= 1}
    //                 onClick={() => { setPageNumber(prevPageNumber => prevPageNumber - 1) }}
    //             >
    //                 <NavArrowLeft />
    //             </Button>

    //             <div className="text-center px-4">
    //                 <input
    //                     type="number"
    //                     className="w-5 text-right mr-2 no-spinners bg-transparent focus:bg-white"
    //                     value={pageNumber}
    //                     onChange={handlePageChange}
    //                 />
    //                 of {numPages || '--'}
    //             </div>

    //             <Button
    //                 disabled={pageNumber >= numPages}
    //                 onClick={() => { setPageNumber(prevPageNumber => prevPageNumber + 1) }}
    //             >
    //                 <NavArrowRight />
    //             </Button>

    //             <Button
    //                 disabled={pageNumber >= numPages}
    //                 onClick={() => { setPageNumber(numPages) }}
    //             >
    //                 <FastArrowRight />
    //             </Button>

    //         </div>
    //     )
    // }

    // const ZoomButtons = () => {
    //     return (
    //         <div className="flex-row">
    //             <Button
    //                 onClick={() => setScale(prevScale => prevScale - 0.25)}
    //                 disabled={scale === 0.25}
    //             ><Minus /></Button>

    //             <Button
    //                 onClick={() => { }}
    //                 disabled={true}
    //                 className={"opacity-75"}
    //             >
    //                 <ZoomIn />
    //             </Button>
    //             <Button

    //                 onClick={() => setScale(prevScale => prevScale + 0.25)}
    //                 disabled={false}
    //             >
    //                 <Plus />
    //             </Button>

    //         </div>
    //     )
    // }

    // const Toolbar = () => {
    //     return (
    //         <div className="flex-row justify-center items-center">
    //             <Button
    //                 onClick={onClose}
    //                 disabled={false}
    //             >
    //                 <Xmark />
    //             </Button>

    //             <Button
    //                 disabled={false}
    //                 onClick={() => setRightPDF(!rightPDF)}
    //             >
    //                 {rightPDF ? (
    //                     <AlignLeftBoxSolid />
    //                 ) : (
    //                     <AlignRightBoxSolid />
    //                 )}
    //             </Button>
    //         </div>
    //     )
    // }

    // const ExpandButton = () => {
    //     return (
    //         <Button
    //             onClick={() => setShowFullscreenPopup(true)}
    //             disabled={false}
    //         >
    //             <Expand />
    //         </Button>
    //     )
    // }

    // const CollapseButton = () => {
    //     return (
    //         <Button
    //             onClick={() => setShowFullscreenPopup(false)}
    //             disabled={false}
    //         >
    //             <Collapse />
    //         </Button>
    //     )
    // }

    // const DownloadButton = () => {
    //     const handleDownload = () => {
    //         fetch(file)
    //             .then(response => response.blob())
    //             .then(blob => {
    //                 const url = URL.createObjectURL(blob);
    //                 const link = document.createElement("a");
    //                 link.href = url;
    //                 link.download = fileName; // Fallback file name if not specified
    //                 link.click();
    //                 URL.revokeObjectURL(url); // Clean up the Blob URL after download
    //             })
    //             .catch(error => console.error("Error downloading the file:", error));
    //     };

    //     return (
    //         <Button onClick={handleDownload} disabled={!file}>
    //             <DownloadSquare />
    //         </Button>
    //     );
    // };

    const OpenWindowButton = () => {
        const handleOpen = () => {
            const link = document.createElement("a");
            link.href = file;
            link.target = "_blank";  // Opens link in a new tab
            link.rel = "noopener noreferrer"; // Adds security for new tab
            link.download = "";  // Optional: set a custom file name here
            link.click();
        };

        return (
            <Button onClick={handleOpen} disabled={!file}>
                <OpenNewWindow />
            </Button>
        );

    }

    const ShareLinkButton = () => {
        const postId = post.id;
        return (
            <Link
                to={`/paperpal/${postId}`}
                target="_blank"
                rel="noopener noreferrer" // Security measure for new tab links
                style={{ textDecoration: 'none', color: 'inherit' }} 
            >
                <Button onClick={() => { }}>
                    <LinkIcon />
                </Button>
            </Link>
        )
    }

    const ViewPdf = ({ expand = true }) => {
        return (
            <div className="shadow-lg shadow-slate-400 border-t-2 border-t-slate-200 h-[600px]">
                <div className="w-full bg-gray-100">
                    {/* 
                    {showToolbar && (
                        <Toolbar />
                    )}
                    <DownloadButton /> */}
                    <OpenWindowButton />
                    <ShareLinkButton />
                    {/* <PageNavigator />

                    {expand ? <ExpandButton /> : <CollapseButton />} */}
                    {/* <ZoomButtons /> */}
                </div>


                <div className=" overflow-auto h-[100%]">
                    {/* <Document
                        file={file}
                        loading={PdfViewerLoadingSkeleton}
                        error={PdfViewerError}
                        noData={PdfViewerLoadingSkeleton}
                        onLoadSuccess={onDocumentLoadSuccess}
                        options={options}>
                        <Page
                            pageNumber={pageNumber}
                            scale={scale}
                        />
                    </Document> */}
                    <div style={{ width: '100%', height: '100%' }}>
                        <embed
                            src={file}
                            width="100%"
                            height="100%"
                            type="application/pdf"
                        />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>

            {showFullscreenPopup ? (
                <Popup
                    open={showFullscreenPopup}
                    closeOnDocumentClick={false}
                    onClose={() => setShowFullscreenPopup(false)}
                >
                    <div className="w-screen h-screen bg-white">
                        <ViewPdf expand={false} />
                    </div>
                </Popup>
            ) : (
                <ViewPdf expand={true} />
            )}

        </div>
    );
}
