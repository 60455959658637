import { SendMail, ShareIos, UserXmark, Xmark } from "iconoir-react";
import Popup from "reactjs-popup";
import useSendShare from "src/api/goodpoint/useSendShare";
import { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import useFetchShare from "src/api/goodpoint/useFetchShare";
import useSendUnshare from "src/api/goodpoint/useSendUnshare";

function ShareButton({ assessmentId, iconOnly = false }) {

    const sendShare = useSendShare(assessmentId);
    const sendUnshare = useSendUnshare(assessmentId);
    const fetchSharedWith = useFetchShare(assessmentId);

    const [waiting, setWaiting] = useState(false);
    const [deleteWaiting, setDeleteWaiting] = useState(false);
    const [shareSuccess, setShareSuccess] = useState(true);
    const [sharedWith, setSharedWith] = useState([]);

    useEffect(() => {
        fetchSharedWith()?.then(result => {
            setSharedWith(result);
        });
    }, [fetchSharedWith]);

    const handleShare = (email) => {
        return sendShare(email).then(result => {
            setShareSuccess(result);
            return result;
        });
    }

    return (
        <Popup
            trigger={
                <ShareButtonContent iconOnly={iconOnly}/>
            }
            modal nested
            contentStyle={{ width: '500px' }}
            onClose={() => {
                setWaiting(false);
                setShareSuccess(true);
                fetchSharedWith().then(result => {
                    setSharedWith(result);
                });
            }}
            onOpen={() => {
                fetchSharedWith().then(result => {
                    setSharedWith(result);
                });
            }}
        >
        {
            // @ts-ignore
            (close) => {
                return (
                    <div className='flex-col p-4 bg-white rounded gap-4 max-w-2/3'>
                        <div className='flex-row justify-between items-start'>
                            <h2>Share Results</h2>
                            <Xmark className='cursor-pointer' onClick={close}/>
                        </div>
                    <label className='text-xs p-1'>Enter the email of the person to share the results with</label>
                    <input type="text" placeholder="Email" className={'outline outline-1 rounded p-1 ' + (shareSuccess ? 'outline-black' : 'outline-red-600')}/>
                    <ShareButtonContent
                        onClick={() => {
                            const emailInput = document.querySelector('input[type="text"]');
                            // @ts-ignore
                            const email = emailInput.value;
                            setWaiting(true);
                            handleShare(email).then(result => {
                                if (result) {
                                    fetchSharedWith().then(result => {
                                        setSharedWith(result);
                                        // @ts-ignore
                                        emailInput.value = "";
                                        setWaiting(false);
                                    });
                                }
                                setWaiting(false);
                            });
                        }}
                        disabled={waiting}
                    />
                    {  sharedWith.length > 0 &&
                        <div className="flex-col gap-1">
                            <hr className="border-1 border-grey-light"/>
                            <div className="flex-col gap-2 max-h-32 overflow-y-scroll p-2">
                                {
                                    sharedWith.map((item) => 
                                    <div className="flex-row justify-between items-center outline outline-1 rounded-small px-2 py-1" key={item.email}>
                                        <p>{item.email.length > 30 ? item.email.substring(0, 27) + "..." : item.email}</p>
                                        {  item.account_exists ?
                                            <Tooltip title="Remove access" placement="left">
                                                <button
                                                    className={
                                                        `grading-type-button flex-row justify-content-center gap-small border-thin rounded-small outline-thin items-center ` +
                                                            (deleteWaiting ? "bg-grey-light outline-transparent" : "bg-red-400 outline-transparent clickable")
                                                    }
                                                    onClick={() => {
                                                        setDeleteWaiting(true);
                                                        sendUnshare(item.email).then(result => {
                                                            if (result) {
                                                                setSharedWith(sharedWith.filter(sharedWith => sharedWith.email !== item.email));
                                                            }
                                                            setDeleteWaiting(false);
                                                        });
                                                    }}
                                                    disabled={deleteWaiting}
                                                >
                                                    <UserXmark strokeWidth={1.5} width={20}/>
                                                </button>
                                            </Tooltip>
                                            :
                                            <Tooltip title="Shared via email" placement="left">
                                                <button
                                                    className={
                                                        `grading-type-button flex-row justify-content-center gap-small border-thin rounded-small outline-thin items-center ` +
                                                            "bg-grey-light outline-transparent"
                                                    }
                                                    disabled={true}
                                                >
                                                    <SendMail strokeWidth={1.5} width={20}/>
                                                </button>
                                            </Tooltip>
                                        }
                                        </div>
                                    )
                                }
                                </div>
                            </div>
                        }
                        </div>
                );
            }
        }
        </Popup>
    )
}

function ShareButtonContent({ onClick = () => {}, iconOnly = false, disabled = false }) {
    return (
        <button
            className={
                `grading-type-button flex-row justify-content-center gap-small border-thin rounded-small outline-thin ` +
                    (disabled ? "bg-grey-light outline-transparent" : "bg-yellow-light outline-transparent clickable")
            }
            onClick={onClick}
            disabled={disabled}
        >
            <ShareIos strokeWidth={1.5}/>
            {!iconOnly && <p>Share Results</p>}
        </button>
    )
}

export default ShareButton;
