import BackLink from 'src/components/nav/BackLink';
import Title from 'src/components/content/Title';
import { Link } from 'react-router-dom';
import { JobsTable } from './JobsPage';
import { TickerInputTable } from './TickerInputs';
import { DatabaseReportList, ReportList } from './ReportList';
import { useState } from 'react';
import { ComponentGuard } from 'src/api/RouteGuard';

function NewsWitchDasboard() {

    const [refreshKey, setRefreshKey] = useState(0);

    // Function to trigger a refresh of ReportsList when a job status changes
    const refreshReportsList = () => {
      setRefreshKey(prevKey => prevKey + 1);
    };

    return (
        <div className="centered">
            <div className="container">
                <BackLink prevPage="Dashboard" href="/dashboard" />
                <Title 
                    title="NewsWitch"
                    subtitle="Daily Reports"
                    
                />
                <div className='flex-col gap-4'>
                    <div className='flex-row gap-4 w-full'>
                        <Link to="latest" className='px-2 py-1 rounded outline outline-1 bg-orange-300 flex-row gap-4 w-full'>
                            <h3>View Latest Report</h3>
                        </Link>
                        <Link to="search" className='px-2 py-1 rounded outline outline-1 bg-orange-300 flex-row gap-4 w-full'>
                            <h3>Ticker Search</h3>
                        </Link>
                    </div>
                    <ComponentGuard roles={["admin"]}>
                        <Link to="public-config" className='px-2 py-1 rounded outline outline-1 bg-orange-300 flex-row gap-4'>
                            <h3>Configure Public Jobs</h3>
                        </Link>
                    </ComponentGuard>
                    <ConfigItem title="Jobs">
                        <JobsTable onJobStatusChange={refreshReportsList}/>
                    </ConfigItem>
                    <ConfigItem title="Tickers">
                        <TickerInputTable />
                    </ConfigItem>
                    <ConfigItem title="Previous Reports" leftItem={
                        <Link to="reports">
                            <p className='text-sm hover:text-orange-500'>View All</p>
                        </Link>
                    }>
                        <div className='max-h-42 overflow-y-auto p-1'>
                            <ReportList key={refreshKey}/>
                        </div>
                    </ConfigItem>
                    <ConfigItem title="WIP: Database Reports">
                        <div className='max-h-42 overflow-y-auto p-1'>
                            <DatabaseReportList key={refreshKey} />
                        </div>
                    </ConfigItem>
                </div>
            </div>
        </div>
    );
}

function ConfigItem({ title, leftItem = undefined, children }) {
    return (
        <div className="flex-col gap-1">
            <hr />
            <div className='flex-row gap-4 justify-between items-end'>
                <h2>{title}</h2>
                {leftItem}
            </div>
            {children}
        </div>
    );
}


export default NewsWitchDasboard;
