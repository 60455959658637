import { useCallback } from "react";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import useCallApi from "src/api/useCallApi";
import { protectedResources } from "src/auth/AuthConfig";

export default function useFetchShare(examId) {
    const callApi = useCallApi({
        msalRequest: {scopes: protectedResources.apiGoodPoint.scopes.write}
    });

    const fetchShare = useCallback(() => {
        return callApi("GET", GoodPointApi.Assessment.Share(examId))
            ?.then(response => {
                if (response.status === 200) {
                    return response.json().then(body => body["shared_with"].reverse());
                }
                return [];
            });
    }, [callApi, examId]);

    return fetchShare;
}
