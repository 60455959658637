import { useContext, useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { GradingImages } from "src/api/goodpoint/Const";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import Divider from "src/components/layout/Divider";
import { AssessmentContext } from "src/pages/goodpoint/assessment/AssessmentContext";
import "./ClassStats.css";
import ClassGeneralFeedback from "src/pages/goodpoint/assessment/tabs/grading/class-performance/ClassGeneralFeedback";
import { AiGenerated } from "src/pages/goodpoint/assessment/components/structure/StructureSection";
import { LoadingCharts } from "src/components/ui/LoadingCharts";
import useCallApi from "src/api/useCallApi";
import ShareButton from "../../../components/ShareButton";
import { useHasRole } from "src/api/useGetRole";

function ClassResults({ examResults }) {
    const { assessmentObject } = useContext(AssessmentContext);
    const readOnly = useHasRole("student");
    
    function getGrade(percentage) {
        for (const [grade, threshold] of Object.entries(assessmentObject.exam_config.grade_thresholds)) {
            if (percentage >= threshold) {
                return grade;
            }
        }
    }

    return <>
        <div className="flex-row justify-content-space-between">
            <div className="flex-row align-baseline gap-small">
                <b>Class Results</b>
                <AiGenerated />
            </div>
            <div className="flex-col align-center">
                <p>Statistics for the whole class</p>
            </div>
        </div>
        <div className="class-average flex-row align-end justify-between">
            <div>
                <h1>{examResults && parseFloat(examResults.class_percent?.toFixed(1))}%</h1>
                <div>
                    <p>{examResults?.class_mark.toFixed(1) ?? undefined}/{examResults?.total_mark} Marks</p>
                    {/* <p>Grade {examResults?.class_grade}</p> */}
                    <p>
                        Grade {examResults && getGrade(parseFloat(examResults.class_percent?.toFixed(1)))}
                    </p>
                </div>
            </div>
            {
                !readOnly && <ShareButton assessmentId={assessmentObject.id} />
            }
        </div>
    </>;
}

export default function ClassStats({ examResults }) {
    const { assessmentObject } = useContext(AssessmentContext);
    const [classDistImgSrc, setClassDistImg] = useState(null);
    const [popupOpen, setPopupOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const callApi = useCallApi();

    useEffect(() => {
        if (classDistImgSrc !== null) return;
        setLoading(true); // Set loading to true when starting to load

        callApi("GET", GoodPointApi.Grading.Images(assessmentObject.id, GradingImages.OVERALL_DIST))
            ?.then(response => {
                if (response.status === 200) {
                    response.blob().then(img => {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            setClassDistImg(reader.result)
                            setLoading(false); // Set loading to false when image is loaded

                        }
                        reader.readAsDataURL(img)
                    })
                } else {
                    setLoading(false); // Set loading to false if the response is not 200
                }
            });
    }, [callApi, assessmentObject, classDistImgSrc])


    return <>
        <div className="class-stats border-mid rounded-small fill-width padding-large">
            <ClassResults examResults={examResults} />
            <Divider lineColour='rgba(0, 0, 0, 0.1)' shadowColour='rgba(0, 0, 0, 0.2)' />
            {loading ? (
                <LoadingCharts />
            ) : (
                <img
                    className="cursor-pointer"
                    src={classDistImgSrc}
                    alt="Class Overall Marks Distribution"
                    onClick={() => { setPopupOpen(true); }}
                />
            )}

            <Divider lineColour='rgba(0, 0, 0, 0.1)' shadowColour='rgba(0, 0, 0, 0.2)' />
            <ClassGeneralFeedback />
        </div>

        <Popup open={popupOpen} closeOnDocumentClick={true} onClose={() => setPopupOpen(false)}>
            <div className='bg-white rounded-small'>
                <img src={classDistImgSrc} alt="Class Overall Marks Distribution" />
            </div>
        </Popup>
    </>
}