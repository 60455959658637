import { useContext, useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { GradingImages } from "src/api/goodpoint/Const";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import Divider from "src/components/layout/Divider";
import { AssessmentContext } from "src/pages/goodpoint/assessment/AssessmentContext";
import { StudentsContext } from "src/pages/goodpoint/assessment/tabs/answers/StudentsContext";
import StudentOverallFeedback from "src/pages/goodpoint/assessment/tabs/grading/student-performance/StudentOverallFeedback";
import { calculatePercentage } from "src/util/StringUtil";
import { LoadingCharts } from "src/components/ui/LoadingCharts";
import useFetchStudentAnswers from "src/api/goodpoint/useFetchStudentAnswers";
import useCallApi from "src/api/useCallApi";

export default function StudentStats({ examResults }) {
    const { assessmentObject } = useContext(AssessmentContext);
    const fetchStudentAnswers = useFetchStudentAnswers(assessmentObject.id);
    const students2 = fetchStudentAnswers();
    const callApi = useCallApi();
    const { selectedStudent } = useContext(StudentsContext);
    const [studentQuantileImgSrc, setStudentQuantileImg] = useState(null);
    const [popupOpen, setPopupOpen] = useState(false);
    const [loading, setLoading] = useState(false); // Loading state

    let percentage = 0;
    if (selectedStudent && examResults) {
        percentage = calculatePercentage(selectedStudent.total_marks, examResults.total_mark);
    }

    function getGrade(percentage) {
        for (const [grade, threshold] of Object.entries(assessmentObject.exam_config.grade_thresholds)) {
            if (percentage >= threshold) {
                return grade;
            }
        }
    }
    useEffect(() => {
        if (!selectedStudent) return;
        setLoading(true); // Set loading to true when starting the API call

        callApi("GET", GoodPointApi.Grading.Images(assessmentObject.id, GradingImages.QUANTILE_PLOT, { student_id: selectedStudent.student_id }))?.then(response => {
            if (response.status === 200) {
                response.blob().then(img => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        setStudentQuantileImg(reader.result)
                        setLoading(false); // Set loading to false when the image is loaded

                    }
                    reader.readAsDataURL(img)
                })
            } else {
                setLoading(false); // Set loading to false in case of an error
            }
        }

        );
    }, [callApi, assessmentObject, studentQuantileImgSrc, selectedStudent]);


    return (
        <div className="student-stats">
            <div className="flex justify-between w-full">
                {students2 && selectedStudent && selectedStudent.student_id && students2[selectedStudent.student_id] && students2[selectedStudent.student_id].name && (
                    <p><b>Name: </b>{students2[selectedStudent.student_id].name}</p>
                )}
                <p><b>ID: </b>{selectedStudent?.student_id}</p>
            </div>

            <Divider lineColour='rgba(0, 0, 0, 0.1)' shadowColour='rgba(0, 0, 0, 0.2)' />
            { /* TODO: Percentage using division can be wrong due to js FP precision */}
            <p>
                <b>Total marks: </b>
                {
                    `${selectedStudent?.total_marks}/${examResults?.total_mark} | ` +
                    `${selectedStudent && examResults && calculatePercentage(selectedStudent.total_marks, examResults.total_mark)}%`
                }
            </p>
            {/* <p><b>Grade: </b>{selectedStudent?.grade}</p> */}
            <p><b>Grade: </b>{getGrade(percentage)}</p>

            <Divider lineColour='rgba(0, 0, 0, 0.1)' shadowColour='rgba(0, 0, 0, 0.2)' />
            <StudentOverallFeedback />
            <Divider lineColour='rgba(0, 0, 0, 0.1)' shadowColour='rgba(0, 0, 0, 0.2)' />

            {loading ? (
                <LoadingCharts />
            ) : (
                <img className="cursor-pointer" src={studentQuantileImgSrc} alt="Student Quantile" onClick={() => { setPopupOpen(true); }} />

            )}

            <Popup open={popupOpen} closeOnDocumentClick={true} onClose={() => setPopupOpen(false)}>
                <div className='bg-white rounded-small'>
                    <img src={studentQuantileImgSrc} alt="Student Quantile" />
                </div>
            </Popup>
        </div>
    )
}