
import { Xmark } from 'iconoir-react';
import Popup from 'reactjs-popup';
import { protectedResources } from 'src/auth/AuthConfig';
import { useEffect, useState } from 'react';

const Subject = ({
    GENERAL:   "General", 
    INVESTMENT: "Investment", 
    DEMO:       "Request a product demo", 
    SUBSCRIBE:  "Subscribe to a service",
    OTHER:      "Other"
});

function ContactPopup({subject=Subject.GENERAL, placeholder = undefined, children}) {
    const [sendEmail, setSendEmail] = useState(false);

    const optionClass = "text-sm afacad-flux-normal"
    if (placeholder === undefined) {
        if (subject === Subject.DEMO) placeholder = "I would like to set up a short meeting to find out more about your products. Please reach out to me by email."
        if (subject === Subject.SUBSCRIBE) placeholder = "I would like to receive a monthly review of the latest scientific papers in generative AI."
    }

    const [emailForm, setEmailForm] = useState({
        subject: subject, 
        body: placeholder
    });

    useEffect(() => {
        if (!sendEmail || emailForm === null) return;
        fetch(protectedResources.apiB2C.endpoint + "/request_for_contact", {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(emailForm),
            headers: {
                "Content-Type": "application/json",
            },
        }).then(response => {
            if (response.ok) {
                console.log("Email sent successfully");
            } else {
                console.error("Failed to send email");
            }
        }).finally(() => {
            setSendEmail(false);
        });
    }, [emailForm, sendEmail]);

    return (
        <Popup modal trigger={children}>
            {close => (
            <div className=' bg-white shadow-xl outline outline-1 outline-gray-200 rounded p-2 container min-w-[50vw] 2xl:min-w-[30vw] relative -top-20 popup-animation-slow'>
                <hr/>
                <div className="flex-row justify-between items-center px-2">
                    <h2 className="my-2 text-md font-bold afacad-flux-normal">
                        Contact
                    </h2>
                    <Xmark className="cursor-pointer" onClick={() => close()} />
                </div>
                <hr/>
                <div className="grid grid-cols-1 gap-2 p-2">
                    <label className="text-sm afacad-flux-normal" >
                        Name
                    </label>
                    <input className="text-sm outline outline-1 rounded py-1 px-2 afacad-flux-normal" 
                        type='text' onChange={(e) => setEmailForm({...emailForm, name: e.target.value})}
                    />
                    <label className="text-sm afacad-flux-normal">
                        Email
                    </label>
                    <input className="text-sm outline outline-1 rounded py-1 px-2 afacad-flux-normal" 
                        type='email' onChange={(e) => setEmailForm({...emailForm, sender: e.target.value})}
                    />
                    <label className="text-sm afacad-flux-normal">
                        Subject
                    </label>
                    <select 
                        className="text-sm rounded py-1 px-2 afacad-flux-normal border-thin"
                        type="text"
                        defaultValue={subject}
                        onChange={(e) => setEmailForm({...emailForm, subject: e.target.value})}
                    >
                            <option className={optionClass }>{Subject.GENERAL}</option>
                            <option className={optionClass}>{Subject.INVESTMENT}</option>
                            <option className={optionClass}>{Subject.DEMO}</option>
                            <option className={optionClass}>{Subject.SUBSCRIBE}</option>
                            <option className={optionClass}>{Subject.OTHER}</option>
                    </select>
                    <label className="text-sm afacad-flux-normal">
                        Message
                    </label>
                    <textarea className="afacad-flux-normal text-sm outline outline-1 rounded py-1 px-2 min-h-20 resize-none" 
                        type='text' rows="4" defaultValue={placeholder} 
                        onChange={(e) => setEmailForm({...emailForm, body: e.target.value})}
                    />
                    <button className="afacad-flux-normal text-sm px-2 py-2 bg-orange-300 hover:bg-orange-400 text-black outline outline-1 rounded"
                        onClick={() => {setSendEmail(true); close()}}
                    >
                        Send
                    </button>
                </div>
            </div>
            )}
        </Popup>
    );
}

export { ContactPopup, Subject };