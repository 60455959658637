import { useState, useLayoutEffect, useCallback } from "react";
import { StepState } from "src/api/goodpoint/Const";
import { Tab, TabState } from "src/api/goodpoint/Const";

export function useTabsState({ assessmentObject, startTab, onChangeTab }) {
    
    const {configure_step, rubric_step, student_answers_step, grading_step} = (assessmentObject ?? {});
    const [tabsState, setTabsState] = useState(undefined);
    const [currentTab, setCurrentTab] = useState(() => {
        if (grading_step === StepState.COMPLETE) {
            return Tab.GRADING;
        }
        return startTab ?? Tab.INFO
    });
    const [nextTab, setNextTab] = useState(undefined);
    const [futureTab, setFutureTab] = useState(undefined);

    const changeTab = useCallback((tab) => {
        if (tab === currentTab) return;
        const oldTab = currentTab;
        var newTabs = { ...tabsState }
        if (currentTab === nextTab) {
            newTabs[currentTab] = TabState.NEXT;
        } else {
            newTabs[currentTab] = TabState.COMPLETE;
        }
        newTabs[tab] = TabState.CURRENT;
        setCurrentTab(tab);
        setTabsState(newTabs);
        onChangeTab && onChangeTab(oldTab, tab);
    }, [currentTab, nextTab, tabsState, onChangeTab]);
    
    useLayoutEffect(() => {
        const newTabs = {
            [Tab.INFO]: TabState.COMPLETE,
            [Tab.RUBRIC]: rubric_step === StepState.COMPLETED ? TabState.COMPLETE : TabState.INCOMPLETE,
            [Tab.ANSWERS]: student_answers_step === StepState.COMPLETED ? TabState.COMPLETE : TabState.INCOMPLETE,
            [Tab.GRADING]: grading_step === StepState.COMPLETED ? TabState.COMPLETE : TabState.INCOMPLETE
        }

        var newNextTab, newFutureTab;

        if (grading_step > StepState.NOT_AVAILABLE) {
            [newNextTab, newFutureTab] = (grading_step === StepState.COMPLETED) 
                ? [null, null] 
                : [Tab.GRADING, null];
        } else if (student_answers_step > StepState.NOT_AVAILABLE) {
            [newNextTab, newFutureTab] = (student_answers_step === StepState.COMPLETED) 
                ? [Tab.GRADING, null]
                : [Tab.ANSWERS, Tab.GRADING];
        } else if (rubric_step > StepState.NOT_AVAILABLE) {
            [newNextTab, newFutureTab] = (rubric_step === StepState.COMPLETED) 
                ? [Tab.ANSWERS, Tab.GRADING]
                : [Tab.RUBRIC, Tab.ANSWERS];
        } else if (configure_step !== StepState.COMPLETED) {
            [newNextTab, newFutureTab] = [Tab.RUBRIC, Tab.ANSWERS];
        }

        for (const t of Object.keys(newTabs)) {
            if (newTabs[t] === TabState.COMPLETE) {
                newTabs[Tab.INFO] = TabState.COMPLETE;
            }
        }

        if (newNextTab != null) {
            newTabs[newNextTab] = TabState.NEXT;
        }

        setNextTab(newNextTab);
        setFutureTab(newFutureTab);
        setTabsState(newTabs);
    }, [
        configure_step,
        rubric_step,
        student_answers_step,
        grading_step
    ])

    useLayoutEffect(() => {
        switch (nextTab) {
            case Tab.RUBRIC: setFutureTab(Tab.ANSWERS); return;
            case Tab.ANSWERS: setFutureTab(Tab.GRADING); return;
            case Tab.GRADING: setFutureTab(null); return;
            default: return;
        }
    }, [nextTab])

    useLayoutEffect(() => {
        if (tabsState == null || tabsState[currentTab] === TabState.CURRENT) return;
        setTabsState({...tabsState, [currentTab]: TabState.CURRENT});
    }, [currentTab, tabsState])

    return {
        tabsState: tabsState,
        currentTab: currentTab,
        nextTab: nextTab,
        futureTab: futureTab,
        changeTab: changeTab
    }
}