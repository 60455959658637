import Popup from "reactjs-popup";
import "./AreYouSureModal.css"
import { WarningTriangleSolid } from "iconoir-react";
export default function AreYouSureModal({
  popupOpen,
  closePopup,
  title,
  content,
  onConfirm,
  danger = false,
  lightButton,
  darkButton,
}) {
  return (
    <Popup
      className="popup-content"
      open={popupOpen}
      closeOnDocumentClick={false}
      onClose={() => closePopup()}
    >
      <div className="bg-white rounded-md shadow-lg p-10">
        <div className="px-2 py-2 text-2xl font-semibold border-b-2 border-gray-400">
          {danger && (
            <WarningTriangleSolid className=" text-yellow-500 text-2xl inline-block mb-2 mr-2" />
          )}
          {title}
        </div>
        <div className="px-2 py-5 text-lg">{content}</div>

        <div className="flex-row gap-large mt-5 justify-end">
          <button
            className="clickable bg-orange-50 border-thin rounded-small py-1 text-lg"
            onClick={() => {
              if (danger) {
                onConfirm();
              }
              closePopup();
            }}
          >
            {lightButton}
          </button>

          <button
            className="clickable bg-orange-mid border-thin rounded-small py-1 text-lg"
            onClick={() => {
              if (!danger) {
                onConfirm();
              }
              closePopup();
            }}
          >
            {darkButton}
          </button>
        </div>
      </div>
    </Popup>
  );
}
