import { useState } from "react";
import { TrashSolid } from "iconoir-react";
import useDeleteAssessment from "src/pages/goodpoint/dashboard/useDeleteAssessment";
import AreYouSureModal from "src/components/ui/AreYouSureModal";
import "./DeleteAssessmentButton.css";
export default function DeleteAssessmentButton({ assessmentItem }) {
    const { deleteAssessment } = useDeleteAssessment();
    const [popupOpen, setPopupOpen] = useState(false);

    return (
        <div>
            <TrashSolid
                onClick={() => setPopupOpen(true)}
                className="h-full p-4 w-14 text-red-500 transition-all duration-200 hover:p-3 cursor-pointer"
            />
            <AreYouSureModal
                popupOpen={popupOpen}
                closePopup={() => setPopupOpen(false)}
                title={"Delete assessment"}
                content={`Are you sure you want to delete ${assessmentItem != null ? assessmentItem.name : ""}?`}
                onConfirm={
                    () => deleteAssessment(assessmentItem.id, () => setPopupOpen(false))
                }
                danger={true}
                lightButton={"Delete"}
                darkButton={"Cancel"}
            />
        </div>
    )
}