import { protectedResources } from 'src/auth/AuthConfig';
import { queryParamsString } from "src/util/StringUtil";

const { REACT_APP_IS_DEV } = process.env;

const PROD_ENDPOINT = protectedResources.apiGoodPoint.endpoint;
const LOCAL_ENDPOINT = "http://localho.st:5000/goodpoint"

const GoodPointApi = { 
    Base: REACT_APP_IS_DEV ? LOCAL_ENDPOINT : PROD_ENDPOINT
}

GoodPointApi.User = `${GoodPointApi.Base}/user`

GoodPointApi.NewAssessment = `${GoodPointApi.Base}/new-assessment`
GoodPointApi.ListAssessments = `${GoodPointApi.Base}/assessments`

const Assessment = (id) => `${GoodPointApi.Base}/assessment/${id}`
Assessment.Config = (id) => `${Assessment(id)}/config`
Assessment.Share = (id) => `${Assessment(id)}/share`
Assessment.Delete = (id) => `${Assessment(id)}/delete`
GoodPointApi.Assessment = Assessment

const Rubric = (id) => `${Assessment(id)}/rubric`
Rubric.Upload = (id) => `${Rubric(id)}/upload`
Rubric.Process = (id) => `${Rubric(id)}/process`
Rubric.Logs = (id) => `${Rubric(id)}/logs`
Rubric.Get = (id, question=null, sub_question=null) => `${Rubric(id)}?${queryParamsString({ question, sub_question })}`
Rubric.All = (id, type) => `${Rubric(id)}/all?${queryParamsString({ type })}`
Rubric.Download = (id, type) => `${Rubric(id)}/download?${queryParamsString({ type })}`
GoodPointApi.Rubric = Rubric

const StudentAnswers = (id) => `${Assessment(id)}/student-answers`
StudentAnswers.Upload = (id) => `${StudentAnswers(id)}/upload`
StudentAnswers.Process = (id) => `${StudentAnswers(id)}/process`
StudentAnswers.Logs = (id) => `${StudentAnswers(id)}/logs`
StudentAnswers.Get = (id, student_id=null, question=null, sub_question=null) =>
    `${StudentAnswers(id)}?${queryParamsString({ student_id, question, sub_question })}`
StudentAnswers.All = (id, student_id=null) => `${StudentAnswers(id)}/all?${queryParamsString({ student_id })}`
StudentAnswers.Download = (id, type, student_id=null) => `${StudentAnswers(id)}/download?${queryParamsString({ type, student_id })}`
StudentAnswers.File = (id, student_id) => `${StudentAnswers(id)}/${student_id}/file`
GoodPointApi.StudentAnswers = StudentAnswers

const Grading = (id) => `${Assessment(id)}/grading`
Grading.Start = (id) => `${Grading(id)}/start`
Grading.Logs = (id) => `${Grading(id)}/logs`
Grading.Results = (id) => `${Grading(id)}/results`
Grading.StudentsResults = (id) => `${Grading(id)}/students`
const Feedback = (id, student_id=null, question_key=null) => `${Grading(id)}/feedback?${queryParamsString({ student_id, question_key })}`
Feedback.All = (id, student_id) => `${Grading(id)}/feedback/all?${queryParamsString({ student_id })}`
Feedback.Download = (id, student_id) => `${Grading(id)}/feedback/download?${queryParamsString({ student_id })}`
Feedback.Overall = { 
    Download: (id) => `${Grading(id)}/feedback/overall/download` 
}
Grading.Feedback = Feedback
const Images = (id, image_name, queryParams={}) => `${Grading(id)}/images/${image_name}?${queryParamsString(queryParams)}`
Grading.Images = Images
GoodPointApi.Grading = Grading

const Output = {
    Download: (id) => `${Assessment(id)}/output/download`
}
GoodPointApi.Output = Output

export default GoodPointApi;