import { useCallback } from "react";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import useMemoFetch from "src/api/useMemoFetch";
import {
  getRubricById,
  saveRubric,
} from "src/storage/RubricStorage";

export default function useFetchRubric(id, type) {
  const cacheGet = useCallback(() => {
    const rubricData = getRubricById(id, type);
    if (rubricData) {
        return rubricData; // If data is still valid, use it
    }
    // If data is expired or not available, return undefined to trigger fetch
    return undefined;
}, [id, type]);

  const cacheSave = useCallback(
    (data) => {
      saveRubric(
        id,
        {
          rubric: data.rubric,
          rubricUrl: data.rubricUrl,
        },
        type
      );
    },
    [id, type]
  );

  return useMemoFetch({
    apiEndpoint: GoodPointApi.Rubric.All(id, type),
    processResponse: (response) =>
      response.json().then((body) => ({
        rubric: body["rubric"],
        rubricUrl: body["rubric_url"], 
      })),
    cacheGet,
    cacheSave,
  });
}


