import { useCallback } from "react";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import useCallApi from "src/api/useCallApi";
import { protectedResources } from "src/auth/AuthConfig";
import { queryParamsString } from "src/util/StringUtil";

export default function useSendUnshare(examId) {
    const callApi = useCallApi({
        msalRequest: {scopes: protectedResources.apiGoodPoint.scopes.write}
    });

    const sendUnshare = useCallback((email) => {
        return callApi("DELETE", `${GoodPointApi.Assessment.Share(examId)}?${queryParamsString({email: email})}`)
            ?.then(response => response.status === 200);
    }, [callApi, examId]);

    return sendUnshare;
}
