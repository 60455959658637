import { ReactComponent as LineGraphIcon } from 'src/components/ui/linegraph.svg';
import { ReactComponent as BoxPlotIcon } from 'src/components/ui/boxplot.svg';
import Loading from './Loading';
export function LoadingCharts(){
    return (
        <div className="relative flex flex-row w-full justify-center items-center shadow-lg">
                <BoxPlotIcon className="ml-24 opacity-10 h-96" />
                <LineGraphIcon className="opacity-10 h-96" />
                <div className="absolute inset-0 flex justify-center items-center rounded-lg">
                    <Loading/>
                </div> 
            </div>
    )
}