import { Link } from 'react-router-dom';
import linkedinImg from 'src/assets/linkedin.png';
import youtubeImg from 'src/assets/youtube.png';

export default function HomeFooter() {
    const row1Links = []
    const row2Links = [
        {"© Zanista AI": "/"},
        {"Terms of Use": "/legal?tab=terms"},
        {"Privacy Policy": "/legal?tab=privacy"},
    ]
    return (
        <div className="container mx-auto flex-row justify-between items-end mt-4 mb-4 md:mb-8">
                <nav className="px-0">
                <ul className="flex-col mx-0 list-none">
                    <li>
                        <div className="flex-row mb-2 pl-2">
                            {
                                row1Links.map((link, href) => {
                                    const key = Object.keys(link)[0];
                                    const value = Object.values(link)[0];
                                    return (
                                        <Link key={href} to={value}
                                            className="text-gray-500 text-xs mr-4 font-bold"
                                        >
                                            {key}
                                        </Link>
                                    );
                                })
                            }
                        </div>
                    </li>
                    <li>
                        <div className="grid grid-cols-2 md:grid-cols-4 align-center gap-4">
                            {
                                row2Links.map((link, href) => {
                                            const key = Object.keys(link)[0];
                                            const value = Object.values(link)[0];
                                            return (
                                                <Link key={href} to={value}
                                                    className="text-xs"
                                                >
                                                    {key}
                                                </Link>
                                            );
                                        })
                            }
                            
                        </div>
                    </li>
                </ul>
                </nav>
                <nav className=''>
                <ul className="flex-row gap-2 list-none">
                    <li className="text-xs">
                        <Link to="https://www.linkedin.com/company/zanista-ai">
                            <img src={linkedinImg} alt="LinkedIn"
                                className="h-4 md:h-5 object-contain"
                            />
                        </Link>
                    </li>
                    <li className="text-xs">
                        <Link to="https://www.youtube.com/@ZanistaAI">
                            <img src={youtubeImg} alt="YouTube"
                                className="h-4 md:h-5 object-contain"
                            />
                        </Link>
                    </li>
                    
                </ul>
                </nav>
        </div>
    );
}
