import { useMsal } from "@azure/msal-react";

function useGetRole() {
    const { instance } = useMsal();
    const currentAccount = instance.getActiveAccount();

    const claimRole = typeof currentAccount.idTokenClaims['extension_Role'] === 'string' ? currentAccount.idTokenClaims['extension_Role'] : "";
    return claimRole;
}

function useHasRole(role) {
    const claimRole = useGetRole();
    return claimRole === role;
}

export { useGetRole, useHasRole };
