import "src/pages/newswitch/components/ReportContainer.css";
import BackLink from 'src/components/nav/BackLink';
import Title from 'src/components/content/Title';
import TickerSearchBar from './components/TickerSearchBar';
import useResetScroll from "src/hooks/useResetScroll";
import { useEffect, useState } from 'react';
import useNewsWitchApi from "src/api/newswitch/useNewsWitchApi";
import NewsLists from './components/NewsLists';
import PPPChart from './components/PPPChart';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { createTheme, ThemeProvider } from '@mui/material';
import SentimentChart from "./components/SentimentChart";
import Select from "react-select";
import "./DateRangeSelect.css";

function NewNewsWitchDasboard() {

    const callAPI = useNewsWitchApi();

    const [ticker, setTicker] = useState(undefined);
    const [tickerToSearch, setTickerToSearch] = useState("NASDAQ:AAPL");
    const [tickerInfo, setTickerInfo] = useState({
        name: "APPLE INC",
        exchange: "NASDAQ",
        ticker: "AAPL"
    });
    const [news, setNews] = useState([]);
    const [prices, setPrices] = useState([]);
    const [pppNews, setPppNews] = useState([]);
    const [startDate, setStartDate] = useState(() => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        yesterday.setHours(0, 0, 0, 0);
        return yesterday;
    });
    const [endDate, setEndDate] = useState(() => {
        const today = new Date();
        today.setHours(23, 59, 59, 999);
        return today;
    });

    const [triggerSearch, setTriggerSearch] = useState(true);

    // TODO: If you change the date or ticker while it is loading, it should cancel the previous request and start a new one.
    // TODO: Get endDate's news first to generate PPP chart faster, then load the rest of the news in bg
    useEffect(() => {
        if (tickerToSearch === undefined) return;
        if (startDate === undefined || endDate === undefined) return;
        if (!triggerSearch) return;
        setNews([]);
        callAPI("GET", "/news?" + new URLSearchParams({
            ticker: tickerToSearch,
            start_date: startDate.toISOString(),
            end_date: endDate.toISOString()
        }).toString())?.then(response => {
            if (response.status === 200) {
                response.json().then(body => {
                    if (body["news"][tickerToSearch] === undefined) {
                        setNews([]);
                        setTicker(tickerToSearch);
                        setTriggerSearch(false);
                        return;
                    }
                    setNews(body["news"][tickerToSearch]);
                    setTicker(tickerToSearch);
                    setTriggerSearch(false);
                });
            } else {
                console.error(response);
                setNews([]);
                setTicker(tickerToSearch);
                setTriggerSearch(false);
            }
        });
    }, [callAPI, tickerToSearch, startDate, endDate, triggerSearch]);

    // Get price data for the PPP
    useEffect(() => {
        if (tickerToSearch === undefined) return;
        if (startDate === undefined || endDate === undefined) return;
        if (!triggerSearch) return;
        callAPI("GET", "/price-data?" + new URLSearchParams({
            ticker: tickerToSearch,
            event_date: endDate.toISOString()
        }).toString())?.then(response => {
            if (response.status === 200) {
                response.json().then(body => {
                    if (body["prices"][tickerToSearch] === undefined) {
                        setPrices([]);
                        return;
                    }
                    setPrices(body["prices"][tickerToSearch]);
                });
            } else {
                console.error(response);
                setPrices([]);
            }
        });
    }, [callAPI, tickerToSearch, startDate, endDate, triggerSearch]);

    useResetScroll();

    const onChange = (start, end) => {
        const newStartDate = start.toDate();
        newStartDate.setHours(0, 0, 0, 0);

        const newEndDate = end.toDate();
        newEndDate.setHours(23, 59, 59, 999);

        if (newStartDate === undefined || newEndDate === undefined) return;

        setStartDate(newStartDate);
        setEndDate(newEndDate);
        setTriggerSearch(true);
    }

    useEffect(() => {
        if (news.length === 0) {
            setPppNews([]);
            return;
        }
        let pppNews = news.filter(n => {
            let date = new Date(n["date"] * 1000).toDateString();
            if (date === "Invalid Date") {
                date = new Date(n["date"]).toDateString();
            }
            return date === endDate.toDateString()
        });
        // If there is no news for today, use yesterday's news
        if (pppNews.length === 0) {
            pppNews = news.filter(n => {
                let date = new Date(n["date"] * 1000).toDateString();
                if (date === "Invalid Date") {
                    date = new Date(n["date"]).toDateString();
                }
                return date === dayjs(endDate).add(-1, 'day').toDate().toDateString();
            });
        }
        setPppNews(pppNews);
    }, [news, endDate]);

    return (
        <div className="centered">
            <div className="container">
                <BackLink prevPage="NewsWitch" href="/newswitch" />
                <div className="flex flex-row justify-between items-center">
                    <Title 
                        title="News Search"
                        subtitle=""
                    />
                    <div className="flex flex-col justify-end items-end mr-4">
                        <h1 className="text-sm">
                            {tickerInfo ? tickerInfo["name"] : ""}
                        </h1>
                        <p className="text-xs">
                            {tickerInfo ? tickerInfo["exchange"] + ":" + tickerInfo["ticker"] : ticker}
                        </p>
                    </div>
                </div>
                <div className='flex-col gap-4 report-container w-full'>
                    <div className="flex flex-row justify-between gap-4 w-full">
                        <div className="flex-col justify-end mb-1 flex-1">
                            <TickerSearchBar onSelect={(selection) => {setTickerInfo(selection); setTickerToSearch(`${selection['exchange']}:${selection['ticker']}`); setTriggerSearch(true)}} maxNameLength={100} />
                        </div>
                        <div className="flex flex-row justify-end items-end">
                            <DateRangePicker onChange={onChange} startDefault={startDate} endDefault={endDate} />
                        </div>
                        <div className="flex-col justify-center min-w-24">
                            <DateRangeSelect onChange={onChange} />
                        </div>
                    </div>
                    {
                        triggerSearch && tickerToSearch && <p>Loading news for <b>{tickerToSearch}</b></p>
                    }
                    { news && news.length > 0 &&
                        <>
                        <div className="section w-full">
                            <NewsLists newsList={news} ticker={ticker} />
                        </div>
                        <div className="w-full">
                            <PPPChart news={pppNews} prices={prices} eventDate={endDate} ticker={ticker}/>
                        </div>
                        <div className="w-full">
                            <SentimentChart ticker={ticker} eventDate={endDate}/>
                        </div>
                        </>
                    }
                    {
                        news && !triggerSearch && ticker !== undefined && <p>No news found for <b>{ticker}</b> ({tickerInfo ? tickerInfo["name"] : ""}).</p>
                    }
                </div>
            </div>
        </div>
    );
}

function DateRangePicker({ onChange = (start, end) => {}, startDefault = undefined, endDefault = undefined}) {
    
    const theme = createTheme({
        palette: {
            primary: {
                main: "#F49569",
                dark: "#EF753D"
            },
            secondary: {
                main: "#F49569"
            }
        },
        typography: {
            fontSize: 11,
        },
    });

    const [startDate, setStartDate] = useState(startDefault ? dayjs(startDefault) : dayjs().add(-1, 'day'));
    const [endDate, setEndDate] = useState(endDefault ? dayjs(endDefault) : dayjs());

    useEffect(() => {
        if (startDefault === undefined || endDefault === undefined) return;
        setStartDate(dayjs(startDefault));
        setEndDate(dayjs(endDefault));
    }, [startDefault, endDefault]);

    const setDateRange = (isStart, date) => {
        if (isStart && !date.isAfter(endDate)) {
            setStartDate(date);
            onChange(date, endDate);
        } else if (!isStart && !date.isBefore(startDate)) {
            setEndDate(date);
            onChange(startDate, date);
        } else if (isStart && date.isAfter(endDate)) {
            setStartDate(date);
            setEndDate(date);
            onChange(date, date);
        } else if (!isStart && date.isBefore(startDate)) {
            setStartDate(date);
            setEndDate(date);
            onChange(date, date);
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ThemeProvider theme={theme}>
                <div className="flex flex-row gap-2 justify-end items-center max-w-72 flex-grow-0 flex-shrink-0 flex-auto">
                    <DatePicker
                        format='DD MMM YYYY'
                        value={startDate}
                        onChange={(event) => setDateRange(true, event)}
                        disableFuture={true}
                    />
                    <p className="text-center">-</p>
                    <DatePicker
                        format='DD MMM YYYY'
                        value={endDate}
                        onChange={(event) => setDateRange(false, event)}
                        disableFuture={true}
                    />
                </div>
            </ThemeProvider>
        </LocalizationProvider>
    )
}

function DateRangeSelect({ onChange = (start, end) => {} }) {

    const customStyles = {
        control: (styles) => ({
          ...styles,
          backgroundColor: 'var(--yellow-light)',
          borderRadius: '4px', 
          boxShadow: '0 0 0 1px var(--orange-mid)', // Optional: subtle shadow matching the border color
          ':hover': {
            borderColor: 'var(--yellow-light)', // Yellow border on hover
          },
        }),
        option: (styles, { isFocused, isSelected }) => ({
          ...styles,
          backgroundColor: isSelected ? 'var(--orange-mid)' : isFocused ? 'var(--yellow-light)' : undefined, // Orange for selected and yellow for hover
          color: isSelected ? 'white' : 'black', // White text when selected, black otherwise
          cursor: 'pointer',
          ':active': {
            ...styles[':active'],
            backgroundColor: isSelected ? 'var(--orange-mid)' : 'var(--yellow-light)', // Same active state as hover
          },
          transition: 'background-color 0.15s ease-in-out', // Smooth transition for background color
        }),
        placeholder: (styles) => ({
          ...styles,
          color: '#ccc',
        }),
        singleValue: (styles) => ({
          ...styles,
          color: 'black', // Style for the selected value (default to black text)
        }),
    };

    const endDate = dayjs();
    const options = [
        {"title": "Today", "start": endDate, "end": endDate},
        {"title": "Yesterday", "start": endDate.add(-1, 'day'), "end": endDate.add(-1, 'day')},
        {"title": "Last Week", "start": endDate.add(-7, 'day'), "end": endDate},
        {"title": "Last Month", "start": endDate.add(-31, 'day'), "end": endDate},
        {"title": "Last Year", "start": endDate.add(-365, 'day'), "end": endDate},
    ];
    const [selectedDate, setSelectedDate] = useState({
        start: options[0].start,
        end: options[0].end
    });
  
    const dateOptions = options.map(
      (option) => {
        return {
          value: {
            start: option.start,
            end: option.end
          },
          label: (
            <div className="date-range-button-grid fill-width"> 
              <p className="text-xs">
                {option.title}
              </p>
            </div>
          )
        };
      }
    );
  
    const handleDateChange = (selectedOption) => {
      if (selectedOption) {
        setSelectedDate(selectedOption.value);
        onChange(selectedOption.value.start, selectedOption.value.end);
      }
    };
  
    return (
      <>
        <Select
        className="basic-single"
        classNamePrefix="select"
        value={dateOptions.find(
          (option) => option.value.start === selectedDate.start && option.value.end === selectedDate.end
        )}
        styles={customStyles}
        isClearable={false}
        isSearchable={false}
        name="date-range"
        options={dateOptions}
        onChange={handleDateChange}
        />
      </>
    );
  }

export default NewNewsWitchDasboard;
