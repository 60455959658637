import BackLink from 'src/components/nav/BackLink';
import { NewAssessment } from "src/pages/goodpoint/dashboard/AssessmentItem"
import Title from 'src/components/content/Title';
import { StepState } from 'src/api/goodpoint/Const';
import AssessmentMenu from 'src/pages/goodpoint/dashboard/AssessmentMenu';
import useAssessmentsList from 'src/pages/goodpoint/dashboard/useAssessmentsList';
import { AssessmentsListContext } from 'src/pages/goodpoint/dashboard/AssessmentsListContext';
import { useLayoutEffect, useState } from 'react';
import Loading from 'src/components/ui/Loading';
import ScrollToTopButton from 'src/components/nav/ScrollToTopButton';
import { useHasRole } from 'src/api/useGetRole';

export function AIGraderDashboard() {
    const {assessments, setAssessments, queriedServer} = useAssessmentsList();
    const isStudent = useHasRole("student");
    
    const [isLoading, setIsLoading] = useState(true);
    useLayoutEffect(() => {
        if (queriedServer || (assessments && Object.keys(assessments).length > 0)) {
            setIsLoading(false);
        }
    }, [assessments, queriedServer]);

    return (
        <div className="centered">
            <div className="container">
                <BackLink prevPage="Dashboard" href="/dashboard" />
                <Title 
                    title="GoodPoint"
                    margin='0px 0px 10px 0px'
                />
                {
                    isLoading 
                        ? <Loading /> 
                        : <>
                            {!isStudent && <NewAssessment />}
                            {
                                isStudent && Object.keys(assessments).length === 0 &&
                                <div className="flex-col gap-large justify-center h-16 mx-2">
                                    <p>No assessments have been shared with this account</p>
                                </div>
                            }
                            <AssessmentsListContext.Provider value={{ assessments, setAssessments }}>
                                {/* Show graded assessments only to students */}
                                {!isStudent && <AssessmentMenu title="Ongoing Assessments" filter={item => item.grading_step !== StepState.COMPLETED} />}
                                <AssessmentMenu title="Graded Assessments" filter={item => item.grading_step === StepState.COMPLETED} />
                            </AssessmentsListContext.Provider>
                        </>
                }
            </div>
            <ScrollToTopButton />
        </div>
    );
}