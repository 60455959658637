
function NewsLists({ ticker, newsList }) {
    const dateLists = {};
    newsList.forEach(item => {
        // if (!item["sentiment"]["direction"] || item["sentiment"]["direction"] === "Irrelevant") return;
        let date = new Date(item["date"] * 1000).toDateString();
        if (date === "Invalid Date") {
            date = new Date(item["date"]).toDateString();
        }
        if (!dateLists[date]) {
            dateLists[date] = [];
        }
        dateLists[date].push(item);
    });
    return (
        <details>
            <summary className="list-item">
                <div className="grid grid-cols-[1fr_auto] items-center">
                    <h2>News summaries for {ticker}</h2>
                    <span className="text-sm font-light ml-2">{newsList.length} News</span>
                </div>
            </summary>
            {
                Object.entries(dateLists).sort(([d1, v1], [d2, v2]) => ((+ (new Date(d1) < new Date(d2))) * 2) - 1).map(([date, newsList]) => {
                    const positiveNews = newsList.filter(item => item["sentiment"]["direction"]?.includes("Positive"));
                    const negativeNews = newsList.filter(item => item["sentiment"]["direction"]?.includes("Negative"));
                    const neutralNews = newsList.filter(item => item["sentiment"]["direction"] === "Neutral");
                    const irrelevantNews = newsList.filter(item => !(item["sentiment"]["direction"]?.includes("Positive") || item["sentiment"]["direction"]?.includes("Negative") || item["sentiment"]["direction"]?.includes("Neutral")));
                    return (
                        <details key={date}>
                            <summary className="list-item">
                                <div className="grid grid-cols-[1fr_auto] ">
                                    Published Date: {date}
                                    <span className="text-sm font-light">{positiveNews.length + negativeNews.length} Important News</span>
                                </div>
                            </summary>
                            <NewsList className="positive" title="Positive News" newsList={positiveNews} />
                            <NewsList className="negative" title="Negative News" newsList={negativeNews} />
                            <NewsList className="neutral" title="Neutral News" newsList={neutralNews} />
                            <NewsList className="neutral" title="Irrelevant News" newsList={irrelevantNews} />
                        </details>
                    );
                })
            }
        </details>
    );
}

function NewsList({ className, title, newsList }) {
    if (newsList.length === 0) return null;
    return (
        <details key={title}>
            <summary className={`list-item ${className}`}>
                <div className="grid grid-cols-[1fr_auto]">
                    {title}
                    <span className="text-sm font-light">{newsList.length} News</span>
                </div>
            </summary>
            <ul>
                {
                    newsList.map(item => {
                        return (
                            <li className="news-item">
                                <a href={item.url} target="_blank" rel="noopener noreferrer">Link to the news 🌐</a>
                                <br />
                                <br />
                                <div className="flex-row justify-between w-full">
                                    <span className="text-sm">Sentiment: {item["sentiment"]["direction"]} - {item["sentiment"]["intensity"]}</span>
                                    <span className="text-sm font-light">Source: {item["domain"]}</span>
                                </div>
                                <br />
                                <b className="text-sm">{item.title}</b>
                                <br />
                                <br />
                                {item["summary"] !== null && item["summary"] !== "" ? item["summary"] : item["snippet"]}
                                <br />
                            </li>
                        );
                    })
                }
            </ul>
        </details>
    )
}

export default NewsLists;