import "./ExamStructure.css";
import ExpandCollapseButtons from "src/components/layout/expand-collapse/ExpandCollapseButtons";
import useExpandCollapse from "src/components/layout/expand-collapse/useExpandCollapse";
import { ExpandCollapseContext } from "src/components/layout/expand-collapse/ExpandCollapseContext";
import { ExamStructureContext } from "src/pages/goodpoint/assessment/components/structure/ExamStructureContext";
import {
  useVisibility,
  Visibility,
  VisibilityContext,
} from "src/pages/goodpoint/assessment/components/structure/Visibility";

import { Xmark } from "iconoir-react";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AssessmentContext,
  EDIT_MODES,
} from "src/pages/goodpoint/assessment/AssessmentContext";
import {
  makeCards,
  reorderStructureByTags,
} from "src/components/ui/Helpers";
import update from "immutability-helper";
import Sortable from "src/pages/goodpoint/assessment/components/Sortable";
import EditToolbar from "src/components/ui/EditToolbar";
import PdfViewer from "src/components/ui/PdfViewer";
import useFetchRubric from "src/api/goodpoint/useFetchRubric";
import { RubricType } from "src/api/goodpoint/Const";

export function ExamStructure({
  structureMap,
  setStructure = (_s) => { },
  saveStructure = (_id, _s, _e) => { },
  addStructureUpdate = (_u) => { },
  HeaderComponent,
  ItemComponent,
  itemCommonProps = null,
  initiallyExpanded = false,
  fileUrl = "",
  listDisplay = [0, 0, 0, 0, 0, 0],
  disableExpandCollapse=false
}) {
  const { assessmentObject, rubricUrl, setRubricUrl, editMode } = useContext(AssessmentContext);
  let assessmentId = "";
  if (assessmentObject){
    assessmentId = assessmentObject.id;
  }

  if (disableExpandCollapse){
    initiallyExpanded = true;
  }
  const expandCollapse = useExpandCollapse(initiallyExpanded);

  // const expandCollapse = disableExpandCollapse ? "expanded" : "collapsed"; // Set default value based on disableExpandCollapse

  const visibilityContextValue = useVisibility(structureMap);
  const { clear, visibilityMap } = visibilityContextValue;
  const countHiddenQuestions = Object.values(visibilityMap).filter(
    (q) => q.visibility in [Visibility.HIDDEN, Visibility.PARTIALLY_HIDDEN]
  ).length;

  const searchVisibility = useVisibility(structureMap);
  const { clear: clearSearchVisibility } = searchVisibility;
  const [isSelectionMode, setIsSelectionMode] = useState(false);
  const [searchTerms, setSearchTerms] = useState([]);
  const clearSearchBar = useRef(() => { });

  //this is what reorder mode shows
  const [cards, setCards] = useState(makeCards(structureMap));

  //pdf
  const { setShowPDF } = useContext(AssessmentContext);

  const fetchOriginalRubric = useFetchRubric(
    assessmentId,
    RubricType.ORIGINAL
  );

  useEffect(() => {
    if (!rubricUrl) {
      let fetchedRubric = fetchOriginalRubric();
      if (fetchedRubric) {
        if ("rubricUrl" in fetchedRubric) {
          setRubricUrl(fetchedRubric["rubricUrl"]);
        }
      }
    }
  })

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setCards((prevCards) => {
      const newCards = update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      });

      return newCards;
    });
  }, []);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setStructure((prevStructure) => {
      const newStructure = reorderStructureByTags(cards, prevStructure);
      saveStructure(
        assessmentId,
        newStructure,
        itemCommonProps?.enhanced ?? false
      );
      return newStructure;
    });
  }, [cards]);
  /* eslint-enable react-hooks/exhaustive-deps */


  const renderCard = useCallback((card, index) => {
    return (
      <Sortable
        key={card.id}
        index={index}
        id={card.id}
        header={card.tag}
        moveCard={moveCard}
      />
    );
  }, [moveCard]);

  const structureItems = useMemo(() => {
    return Object.entries(structureMap)
      .sort(
        ([, a], [, b]) => Number(a.question_number) - Number(b.question_number)
      ) // Convert index to number for correct sorting
      .map(([itemKey, itemData]) => (
        <ItemComponent
          key={itemKey}
          itemData={itemData}
          outerTags={[]}
          {...(itemCommonProps ?? {})}
        />
      ));
  }, [structureMap, itemCommonProps]);

  const structureSortables = () => {
    return <>{cards.map((card, i) => renderCard(card, i))}</>;
  };

  function onChangeSearchText(text) {
    const newSearchTerms = text
      .split(",")
      .map((s) => s.trim())
      .filter((s) => s.length > 0);
    setSearchTerms(newSearchTerms);
  }

  function clearSearchTerms() {
    clearSearchVisibility();
    setSearchTerms([]);
    setIsSelectionMode(false);
  }

  const { showPDF, rightPDF } = useContext(AssessmentContext);
  return (
    <ExpandCollapseContext.Provider value={expandCollapse}>
      <ExamStructureContext.Provider
        value={{
          structureMap,
          ItemComponent,
          isSelectionMode,
          itemCommonProps: itemCommonProps ?? {},
          searchTerms,
          clearSearch: () => clearSearchBar.current(),
          searchVisibility,
        }}
      >
        <VisibilityContext.Provider value={visibilityContextValue}>
          <div className="exam-structure">
            <div className="flex-row gap-large justify-between mb-8">
              <HeaderComponent structureMap={structureMap} />
              {!disableExpandCollapse && <ExpandCollapseButtons />} {/* Conditionally render ExpandCollapseButtons */}
            </div>
            <div className="structure-content flex-col gap-mid">
              {countHiddenQuestions > 0 && !isSelectionMode && (
                <div
                  className="clear-hidden fg-orange-dark flex-row align-center cursor-pointer"
                  onClick={() => clear()}
                >
                  <p>{`${countHiddenQuestions} hidden question${countHiddenQuestions > 1 ? "s" : ""
                    } (Clear)`}</p>
                  <Xmark strokeWidth={1.0} />
                </div>
              )}
              <EditToolbar
                setStructure={setStructure}
                setCards={setCards}
                saveStructure={saveStructure}
                addStructureUpdate={addStructureUpdate}
                enhanced={itemCommonProps && itemCommonProps.enhanced ? itemCommonProps.enhanced : null}
                isSelectionMode={isSelectionMode}
                setIsSelectionMode={setIsSelectionMode}
                clearSearchBar={clearSearchBar}
                onChangeSearchText={onChangeSearchText}
                clearSearchTerms={clearSearchTerms}
                listDisplay={listDisplay}
              />

              <div className="flex flex-row gap-4">
                <div className={`${showPDF && rightPDF ? "order-1" : "order-2"} ${showPDF ? "w-1/2" : "w-full"} structure-content flex flex-col gap-small`}>
                  {editMode !== EDIT_MODES.REORDER && structureItems}
                  {editMode === EDIT_MODES.REORDER && structureSortables()}
                </div>

                {showPDF && (
                  <div className={`${rightPDF ? "order-2" : "order-1"} w-1/2`}>
                    <PdfViewer file={fileUrl} onClose={() => setShowPDF(false)}/>
                  </div>
                )}
              </div>

            </div>
          </div>
        </VisibilityContext.Provider>
      </ExamStructureContext.Provider>
    </ExpandCollapseContext.Provider>
  );
}
