export function makeCards(structure) {
  return Object.keys(structure)
    .reduce((acc, key) => {
      acc.push({
        tag: structure[key].tag, // Copy the tag attribute
        id: Number(structure[key].question_number), // Use the item's index from the structure and convert to a number if necessary
      });
      return acc;
    }, [])
    .sort((a, b) => a.id - b.id); // Sort the array by id (numerically)
}

//update the rubric (structure) based on the ordering in cards
export function reorderStructureByTags(newCards, structure) {
  const tagToIndexMap = newCards.reduce((map, card, index) => {
    map[card.tag] = index;
    return map;
  }, {});

  // Convert the structure object into an array, process it, then convert it back to an object
  let reorderedStructure = Object.entries(structure).map(([key, item]) => {
    // Assuming each 'item' is an object that includes a 'tag'
    return {
      ...item,
      question_number: tagToIndexMap[item.tag], // Update the index based on the tag
    };
  });

  // Sort the array based on the new index
  reorderedStructure.sort((a, b) => a.question_number - b.question_number);

  // Convert the array back to an object with the original keys
  let resultStructure = {};
  reorderedStructure.forEach((item, idx) => {
    resultStructure[item.tag] = item;
  });

  return resultStructure;
}

function adjustMarksTopDown(currentNode, mark) {
  let childrenMarks = Object.values(currentNode["sub_questions"]).map(
    (q) => q.mark
  );
  const newChildrenMarks = adjustListToSum(childrenMarks, mark);
  const questionKeys = Object.keys(currentNode["sub_questions"]);

  questionKeys.forEach((questionKey, index) => {
    currentNode["sub_questions"][questionKey] = {
      ...currentNode["sub_questions"][questionKey],
      mark: newChildrenMarks[index],
    };

    // If there are further nested sub_questions, recurse
    if (
      Object.keys(currentNode["sub_questions"][questionKey].sub_questions || {})
        .length > 0
    ) {
      adjustMarksTopDown(
        currentNode["sub_questions"][questionKey],
        newChildrenMarks[index]
      );
    }
  });
}

function adjustStepMarksTopDown(currentNode, mark) {
  let childrenMarks = Object.values(currentNode["steps"]).map((q) =>
    parseFloat(q.step_mark)
  );
  const newChildrenMarks = adjustListToSum(childrenMarks, mark);

  for (let i = 0; i < currentNode.steps.length; i++) {
    currentNode.steps[i].step_mark = newChildrenMarks[i];
  }
}

export function adjustMarksBottomUp(rubric) {
  function calculateMarks(question) {
    let totalMark = 0;
    if (
      question.sub_questions &&
      Object.keys(question.sub_questions).length > 0
    ) {
      for (const subKey in question.sub_questions) {
        const subQuestion = question.sub_questions[subKey];
        totalMark += calculateMarks(subQuestion); // Recursively calculate marks for sub-questions
      }
      question.mark = totalMark;
    } else {
      totalMark = question.mark;
    }
    return totalMark;
  }

  for (const key in rubric) {
    const question = rubric[key];
    calculateMarks(question);
  }

  return rubric;
}

//modes - content, delete, marks
export function updateNestedStructure(
  obj,
  keys,
  values = {},
  mode = "content",
  step_index = 0
) {
  let current = obj;

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];

    if (
      !current[key] ||
      (i < keys.length - 1 && !current[key]["sub_questions"])
    ) {
      // console.error("The expected structure does not exist.");
      return;
    }

    // If not the last key, dive into 'sub_questions', otherwise prepare to update
    if (i < keys.length - 1) {
      if (!current[key]["sub_questions"]) {
        console.error(`Sub-questions for key '${key}' not found.`);
        return;
      }
      current = current[key]["sub_questions"];
    } else {
      if (mode === "delete") {
        let question = current[key];
        delete current[key];
        return question;
      } else if (mode === "content") {
        current[key] = { ...current[key], ...values };
      } else if (mode === "mark") {
        current[key] = { ...current[key], ...values };
        if (
          current[key]["sub_questions"] &&
          Object.keys(current[key]["sub_questions"]).length > 0
        ) {
          adjustMarksTopDown(current[key], values.mark);
        } else {
          adjustStepMarksTopDown(current[key], values.mark);
        }
      } else if (mode === "marks") { //so fucking annoying that some of it is marks and some of it is mark
        current[key] = { ...current[key], ...values };
      } else if (mode === "steps") {
        current[key]["steps"][step_index] = {
          ...current[key]["steps"][step_index],
          ...values,
        };
        const steps = current[key]["steps"]

        let totalMark = 0;
        current[key]["steps"].forEach((item) => {
          totalMark += parseFloat(item.step_mark); // Ensure it's parsed as a float
        });

        current[key]["mark"] = totalMark;
        return steps;
      } else if (mode === "question_feedback"){
        current[key]["question_feedback"] = values;
      }
    }
  }
}

//top down adjusting marks
export function adjustListToSum(lst, targetSum) {
  function roundToHalf(value) {
    return Math.round(value * 2) / 2;
  }

  const originalSum = lst.reduce((acc, val) => acc + val, 0);
  const multiplier = originalSum !== 0 ? targetSum / originalSum : 1;

  let adjustedLst = lst.map((num) => roundToHalf(num * multiplier));
  adjustedLst.reverse();

  let diff = parseFloat(
    (targetSum - adjustedLst.reduce((acc, val) => acc + val, 0)).toFixed(1)
  );
  let countedAdj = 0;

  while (Math.abs(diff) > 0.01 && countedAdj <= 100) {
    countedAdj += 1;
    for (let i = 0; i < adjustedLst.length; i++) {
      if (diff > 0) {
        adjustedLst[i] += 0.5;
        diff = parseFloat((diff - 0.5).toFixed(1));
        if (Math.abs(diff) < 0.01) break;
      } else if (diff < 0) {
        adjustedLst[i] -= 0.5;
        diff = parseFloat((diff + 0.5).toFixed(1));
        if (Math.abs(diff) < 0.01) break;
      }
    }
  }
  adjustedLst.reverse();
  return adjustedLst;
}


export function isRubric(structure = {}, heading = "") {
  if (structure) {
    return Object.keys(structure).some(
      (key) => "question_text" in structure[key]
    );
  }
  if (heading) {
    return heading !== "Answer" && heading !== "Feedback";
  }
}

export function isStudentPerformance(structure){
  if (structure){
    return Object.keys(structure).some(
      (key) => "feedback" in structure[key]
    );
  }
}

export function tokenizeText(text){
  if (!text){
    return {};
  }
  // Regex to match LaTeX between $...$ or $$...$$
  const regex = /(\$\$[\s\S]*?\$\$|\$.*?\$)/g;
  const tokens = text.split(regex);

  // Create tokens with the filter condition applied
  let filteredTokens = tokens
    .map((token, index) => {
      if (token.trim() === "") {
        return null; // Return null for empty tokens
      }
      if (token.startsWith("$$") && token.endsWith("$$")) {
        return { type: "latex", content: token, id: index };
      }
      if (token.startsWith("$") && token.endsWith("$")) {
        return { type: "latex", content: token, id: index };
      }
      return { type: "text", content: token, id: index };
    })
    .filter((token) => token !== null); // Filter out null tokens

  if (filteredTokens[filteredTokens.length - 1].type === "latex") {
    filteredTokens.push({
      id: filteredTokens.length,
      content: "",
      type: "text",
    });
  }
  // Update the IDs of the remaining tokens to be consecutive
  filteredTokens = filteredTokens.map((token, index) => ({
    ...token,
    id: index,
  }));

  return filteredTokens;
};

export function unTokenizeText(tokens){
  if (!Array.isArray(tokens)) {
    return "";
  }
  const content = tokens.map((token) => token.content).join("");
  return content;
}

export function addKeysToSubquestions(data, parentKeys = []) {
  const result = {};

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const currentKeys = [...parentKeys, key];

      const valueWithKeys = { ...data[key], keys: currentKeys };

      if (valueWithKeys.sub_questions) {
        valueWithKeys.sub_questions = addKeysToSubquestions(
          valueWithKeys.sub_questions,
          currentKeys
        );
      }

      result[key] = valueWithKeys;
    }
  }

  return result;
}

export const findNestedQuestionText = (itemData, rubric) => {
  if (!itemData || !rubric){
    return;
  }
  const keys = itemData.keys;
  let current = rubric;

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];

    if (!current[key]) {
      console.error(`Key '${key}' not found in the rubric.`);
      return null; // Return null if key doesn't exist
    }

    // If not the last key, dive into 'sub_questions'
    if (i < keys.length - 1) {
      current = current[key]["sub_questions"];
      
      // Ensure that sub_questions exist
      if (!current) {
        console.error(`Sub-questions for key '${key}' not found.`);
        return null; // Return null if sub_questions don't exist
      }
    } else {
      // Return question_text if it's the last key
      return current[key].question_text || null; 
    }
  }
};