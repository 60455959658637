import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { NavArrowDown } from 'iconoir-react';
import { useState } from 'react';


function QuestionItem({ question, answer, color = "white", expanded, onChange }) {
  return (
    <Accordion 
      expanded={expanded} 
      onChange={onChange}
      disableGutters
      >
      <AccordionSummary
        expandIcon={<NavArrowDown />}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{ backgroundColor: color, }}
      >
        <div className={`bg-${color}`}>
          <p className="afacad-flux-normal lg:text-lg text-sm">{question}</p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <p className="afacad-flux-normal lg:text-md text-sm">{answer}</p>
      </AccordionDetails>
    </Accordion>
  )
}
export default function FAQAccordion() {
  const [expanded, setExpanded] = useState(null);

  return (
    <div>
      <QuestionItem
        color="#ffe8d4"
        question="How much does GoodPoint cost?"
        answer="Our pricing is based on the number of students and the number of exams you want to grade."
        expanded={expanded === 'panel1'}
        onChange={() => setExpanded(expanded === 'panel1' ? false : 'panel1')}
      />
      <QuestionItem
        question="How does GoodPoint work?"
        answer="GoodPoint carries out many detailed steps including: Pre-processing, OCR Extraction, Rubric Enhancement, Marking and Feedback."
        expanded={expanded === 'panel2'}
        onChange={() => setExpanded(expanded === 'panel2' ? false : 'panel2')}
      />
      <QuestionItem
        color="#ffe8d4"
        question="Is GoodPoint accurate?"
        answer="GoodPoint's methods and results are triple checked to be as accurate as possible."
        expanded={expanded === 'panel3'}
        onChange={() => setExpanded(expanded === 'panel3' ? false : 'panel3')}
      />
      <QuestionItem
        question="Is my data safe with GoodPoint?"
        answer="At Zanista AI, we ensure GDPR-compliant data protection, emphasizing strict security measures without saving or sharing user data with third parties."
        expanded={expanded === 'panel4'}
        onChange={() => setExpanded(expanded === 'panel4' ? false : 'panel4')}
      />
    </div>
  );
}
