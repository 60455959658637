import { ExamStructure } from "src/pages/goodpoint/assessment/components/structure/ExamStructure";
import "./AnswersStructure.css"
import AnswerItem from "src/pages/goodpoint/assessment/tabs/answers/structure/AnswerItem";
import { useState, useEffect, useContext } from "react";
import { setAllStudentAnswers } from "src/storage/StudentAnswerStorage";
import { getGradingAllStudentsResults, saveGradingAllStudentsResults } from "src/storage/GradingStorage";
import { AssessmentContext } from "../../../AssessmentContext";
import LoadingTab from "src/components/ui/LoadingTab";
import { useHasRole } from "src/api/useGetRole";

export default function AnswersStructure({ selectedStudent, structureMap }) {
    const [name, setName] = useState(selectedStudent.name);
    const { setStudents, assessmentObject } = useContext(AssessmentContext);
    useEffect(() => {
        setName(selectedStudent.name);
    }, [selectedStudent])

    const handleNameChange = (event) => {
        const newName = event.target.value;
        setStudents(prevState => {
            const updatedStudents = { ...prevState };
            updatedStudents[selectedStudent.student_id].name = newName;
            setAllStudentAnswers(assessmentObject.id, updatedStudents);
            return updatedStudents;
        })
        const updatedStudentResults = getGradingAllStudentsResults(assessmentObject.id);
        if (updatedStudentResults && updatedStudentResults.hasOwnProperty(selectedStudent.student_id)) {
            updatedStudentResults[selectedStudent.student_id].name = newName;
            saveGradingAllStudentsResults(assessmentObject.id, updatedStudentResults);
        }
        setName(newName);
    }

    // const endpoint = GoodPointApi.StudentAnswers.File(assessmentObject.id, selectedStudent.student_id)

    // const fetcher = url => axios.get(url).then(res => res.data);
    // const { data, isLoading, error } = useSWR(endpoint, fetcher, {
    //     revalidateOnFocus: false,
    //     revalidateOnReconnect: false,
    //     refreshInterval: 172000
    // });

    const readOnly = useHasRole("student");

    function AnswersHeader({ structureMap }) {
        const numQuestions = Object.keys(structureMap).length;
        return (
            <div className="grid grid-cols-3">
                <div className=""><b>ID:</b> {selectedStudent.student_id}</div>
                {
                    !readOnly &&
                    <div className=""><b>Name: </b><input
                        className="border-2 border-gray-100 rounded-lg px-2 focus:outline-black hover:border-2 hover:border-[var(--orange-dark)]"
                        defaultValue={name}
                        onBlur={handleNameChange}
                        disabled={readOnly}
                    /></div>
                }
                <div className=" justify-self-end text-right"><p>{numQuestions} Question{(numQuestions > 1) ? "s" : ""} Answered</p>
                </div>
            </div>
        )
    }

    if (!structureMap) {
        return <LoadingTab />
    }

    return (
        <div className="flex-col gap-mid">
            <ExamStructure
                structureMap={structureMap}
                HeaderComponent={AnswersHeader}
                ItemComponent={AnswerItem}
                listDisplay={[0, 0, 1, 1, 1, 0]}
                // fileUrl={isLoading || error ? "" : data.answerUrl}
            />
        </div>
    )
}