import armanProfileImg from "src/assets/home/team/arman.jpeg"
import narimanProfileImg from "src/assets/home/team/nariman.jpeg"
import jeffProfileImg from "src/assets/home/team/jeff.jpeg"
import benjaminProfileImg from "src/assets/home/team/benjamin.jpeg"
import emilProfileImg from "src/assets/home/team/emil.jpeg"
import robProfileImg from "src/assets/home/team/rob.jpeg"
import alirezaProfileImg from "src/assets/home/team/alireza.jpeg"
import lexaProfileImg from "src/assets/home/team/lexa.jpeg"

const armanBio = `Arman Khaledian is the director and co-founder of Zanista AI, a startup established in August 2023 with the goal of revolutionizing education through advanced AI and Large Language Models (LLM) to enhance both learning and teaching experiences. One of Zanista AI's main products is an AI grader designed to automate the grading of exams in universities and schools, streamlining educators' workflows. In addition to his role at Zanista AI, Arman has been dedicated to mentoring students with IntoUniversity, supporting their career aspirations in London.
Before beginning his entrepreneurial journey, Arman was a visiting lecturer at Imperial College Business School, sharing his knowledge in financial engineering, algorithmic trading, and high-frequency trading (HFT). He has held quantitative positions at Millennium and UBS, focusing on using machine learning algorithms to enhance trading efficiency and risk management.
Arman started his career at Bank of America in 2018 as an equity quant, working on systematic investment strategies and models for trading costs and risks. He also made contributions to academia as a teacher and researcher at Imperial College, specializing in financial mathematics.
Arman obtained his PhD in Financial Mathematics from Imperial College London, researching functional Itô calculus and stochastic Taylor expansions. His academic journey also includes earning a Master's and a Bachelor's degree in mathematics from Sharif University of Technology, providing a foundation for his contributions to both the financial and educational sectors through technology.
Email: arman.khaledian@zanista.ai
LinkedIn: https://www.linkedin.com/in/armankhaledian/`

const narimanBio = `Nariman is co-founder of ZanistaAI, and since its foundation in August 2023, he has dedicated his full focus to overcoming scientific and engineering challenges in developing high-quality products at ZanistaAI. When approaching a challenge, Nariman's mindset is to find the most innovative, simple, and minimalistic approach, ensuring that customers receive what they need.
By conducting research at the Inria Research Center in Nancy, France, and working with heart surgeons and researchers in the Harvard Biorobotics Lab, he completed his academic journey by obtaining his PhD in Cardiovascular Simulations and Medical Imaging. During his PhD, with his persistence in tackling challenging tasks, he managed to obtain two best paper awards and publish scientific papers in the most prestigious journals of medical imaging and heart modeling.
His background is in engineering, as he started his studies as an Aerospace Engineer at Sharif University of Technology, Tehran, Iran, and completed a Master of Science in Aerodynamics at K.N. Toosi University, Tehran, Iran. After exploring different aspects of engineering work, he found his interest in computer science and numerical analysis.
Email: nariman.khaledian@zanista.ai
LinkedIn: https://www.linkedin.com/in/nariman-khaledian/`

const alirezaBio = `Alireza Kargarzadeh is a Quantitative & Generative AI Researcher Intern, dedicated to exploring the intersection of advanced technology and finance. Having graduated from Imperial College London with an MSc in Mathematics and Finance, Alireza brings a unique blend of skills and a forward-thinking mindset to the field.
With a deep interest in the application of cutting-edge technology, such as Generative AI and Large Language Models (LLM), Alireza aims to leverage these advancements to innovate within the field of quantitative finance. Throughout his education, Alireza has consistently engaged in high-level research, contributing to academic discourse.
Alireza's journey is marked by a strong engineering background, which, combined with his recent academic pursuits in quantitative finance, has equipped him with a robust foundation in both theoretical and practical aspects of mathematics and finance. This unique combination of skills enables Alireza to approach complex financial challenges with innovative solutions, leveraging the power of AI and advanced quantitative techniques.
Email: alireza.kargarzadeh@zanista.ai
LinkedIn: https://www.linkedin.com/in/alireza-kargarzadeh/`

export const team = [
    {
        name: 'Arman Khaledian',
        title: 'Co-founder',
        image: armanProfileImg,
        link: 'https://www.linkedin.com/in/armankhaledian/',
        bio: armanBio
    },
    {
        name: 'Nariman Khaledian',
        title: 'Co-founder',
        image: narimanProfileImg,
        link: "https://www.linkedin.com/in/nariman-khaledian/",
        bio: narimanBio
    },
    {
        name: 'Jeff Katz',
        title: 'Senior Strategic Advisor',
        image: jeffProfileImg,
        link: "https://www.linkedin.com/in/jeff-katz-3a8515145/",
        bio: armanBio
    },
    {
        name: 'Benjamin Katz',
        title: 'Strategy Consultant',
        image: benjaminProfileImg,
        link: "https://www.linkedin.com/in/bennykatz/",
        bio: armanBio
    },
    {
        name: 'Emil Filip',
        title: 'AI and Educational Technology Consultant',
        image: emilProfileImg,
        link: "https://www.linkedin.com/in/emil-filip-dev/",
        bio: armanBio
    },
    {
        name: 'Rob Wakefield',
        title: 'AI and Technology Officer',
        image: robProfileImg,
        link: "https://www.linkedin.com/in/rob-wakefield/",
        bio: armanBio
    },
    {
        name: 'Alireza Kargarzadeh',
        title: 'Quantative & Generative AI Research Intern',
        image: alirezaProfileImg,
        link: "https://www.linkedin.com/in/alireza-kargarzadeh-234474168/",
        bio: alirezaBio
    },
    {
        name: 'Lexa Huang',
        title: 'AI and Educational Technology Intern',
        image: lexaProfileImg,
        link: "https://www.linkedin.com/in/lexahuang/",
        bio: armanBio
    },
]