import './FileUploader.css'
import { useRef, useContext } from 'react';
import { Timer, Upload } from 'iconoir-react';
import { useFileUpload } from 'src/api/useFileUpload';
import { AssessmentContext } from 'src/pages/goodpoint/assessment/AssessmentContext';

function UploadButton({ uploadEndpoint, multiple=true, formDataKey="files", onUpload=null }) {
    const {isUploading, upload} = useFileUpload(uploadEndpoint, formDataKey);
    const {setFile} = useContext(AssessmentContext); 

    const fileRef = useRef(null);

    const onButtonClick = () => {
        if (isUploading) return;
        fileRef.current.click();
    }

    const onChange = (event) => {
        const { files } = event.target;
        const nextFile = files && files[0];

        if (nextFile) {
            setFile(nextFile);
        }
        
        upload(fileRef.current.files, onUpload);

    }

    return (
        <div 
            className={
                `upload-button bg-orange-light border-mid border-black rounded-mid padding-large ` + 
                `${isUploading ? "disabled" : "clickable"}`
            }
            onClick={onButtonClick}
        >
            <div className='padding-large'>
            {
                isUploading ? <Timer style={{color: "var(--orange-dark)"}} height={128} width={128} />
                            : <Upload style={{color: "var(--orange-dark)"}} height={128} width={128} />
            }
            </div>
            <input type='file' ref={fileRef} style={{display: 'none'}} onChange={onChange} multiple={multiple} />
        </div>
    )
}

export default function FileUploader({ title, text, onUpload, uploadEndpoint, formDataKey="files" }) {
    return (
        <div className="centered">
            <div className="file-upload margin-x-large flex-col gap-large align-center">
                <UploadButton uploadEndpoint={uploadEndpoint} formDataKey={formDataKey} onUpload={onUpload} />
                <div className="gap-mid flex-col align-center padding-large">
                    <h2>{title}</h2>
                    <p>{text}</p>
                </div>
            </div>
        </div>
    )
}
