import { useContext } from "react";
import { ExamStructure } from "src/pages/goodpoint/assessment/components/structure/ExamStructure";

import "./RubricStructure.css";
import { RubricItem } from "src/pages/goodpoint/assessment/tabs/rubric/structure/RubricItem";
import { AssessmentContext } from "../../../AssessmentContext";
import { saveRubric } from "src/storage/RubricStorage";
import LoadingTab from "src/components/ui/LoadingTab";

export default function RubricStructure({ rubric, enhanced = false }) {
    const { setRubric, addRubricUpdate, rubricUrl } =
        useContext(AssessmentContext);

    function RubricHeader({ structureMap }) {
        const numQuestions = Object.keys(structureMap).length;
        const totalMarks =
            Object
                .values(structureMap)
                .reduce((acc, q) => q.mark ? acc + q.mark : acc, 0);

        return <div className="flex-row align-center gap-large">
            <p>{numQuestions} Question{(numQuestions > 1) ? "s" : ""}</p>
            <p>Total Marks: <b>{totalMarks}</b></p>
        </div>
    }

    if (!rubric) {
        return <LoadingTab />;
    }

    return (
        <div className="flex-col gap-mid">
            <ExamStructure
                structureMap={rubric}
                setStructure={setRubric}
                saveStructure={saveRubric}
                addStructureUpdate={addRubricUpdate}
                HeaderComponent={RubricHeader}
                ItemComponent={RubricItem}
                itemCommonProps={{ enhanced }}
                fileUrl = {rubricUrl}
                listDisplay={[1, 1, 1, 1, 1, 1]}
            />
        </div>
    );
}
