import Button from "src/components/ui/Button";
import { useState } from "react";
import "//unpkg.com/mathlive";
import "./Keyboard.css";
// import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
import Select from 'react-select';

export const customStyles: StylesConfig = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white', // Light gray background
    borderRadius: '8px', // Border radius as per your original
    boxShadow: '0 0 0 1px #d3d3d3', // Light gray border
    ':hover': {
      borderColor: '#d3d3d3', // Light gray border on hover
    },
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isSelected ? '#e5e7eb' : isFocused ? '#f1f5f9' : undefined, // bg-slate-200 and bg-slate-100
    color: isSelected ? 'black' : 'black', // Black text for both states
    cursor: 'pointer',
    ':active': {
      ...styles[':active'],
      backgroundColor: isSelected ? '#e0e0e0' : '#f5f5f5', // Same active state as hover
    },
    transition: 'background-color 0.15s ease-in-out', // Smooth transition for background color
  }),
  placeholder: (styles) => ({
    ...styles,
    color: '#a9a9a9', // Light gray placeholder color
  }),
  singleValue: (styles) => ({
    ...styles,
    color: 'black', // Black text for the selected value
  }),
};



export default function PopupMathField({
  setIsModalOpen,
  tokenId,
  tokens,
  setTokens,
  setHistoryTokens,
  historyIndex,
  left,
  right,
}) {
  let initValue = "";
  let delimiter = "$";

  if (tokenId !== -1) {
    if (tokens[tokenId].type === "latex") {
      const latexContent = tokens[tokenId].content;
      delimiter = latexContent.startsWith("$$") ? "$$" : "$";
      initValue = latexContent
        .replace(/^\$\$|\$\$$/g, "")
        .replace(/^\$|\$$$/g, "");
    }
  }

  const [display, setDisplay] = useState(delimiter); //inline or block display
  const [value, setValue] = useState(initValue); //value of the equation being edited

  const saveLatex = (value, tokenIndex, left = "", right = "") => {
    let updatedTokens;
    //adding an equation at the end
    if (
      tokenIndex === -1 ||
      (tokenIndex >= 0 && tokens[tokenIndex].content === "") ||
      (tokenIndex === tokenId.length && tokens[tokenIndex].type === "text")
    ) {
      updatedTokens = [
        ...tokens,
        { id: tokens.length, content: value, type: "latex" },
      ];
    } else {
      //directly updating the current equation
      if (left.trim() === "" && right.trim() === "") {
        if (tokens[tokenId].type === "latex") {
          updatedTokens = tokens.map((token) => {
            if (token.id === tokenIndex) {
              return { ...token, content: value, type: "latex" };
            }
            return token;
          });
        } else {
          //adding an equation between 2 equations
          updatedTokens = [
            ...tokens.slice(0, tokenIndex),
            { id: tokenIndex, content: value, type: "latex" },
            ...tokens.slice(tokenIndex + 1),
          ];
        }
      } else {
        //inserting equation in the middle of text
        const newTokens = [];

        // Add the left part only if it's not empty
        if (left) {
          newTokens.push({ id: tokenIndex, content: left, type: "text" });
        }

        // Add the new LaTeX token
        newTokens.push({
          id: tokenIndex + newTokens.length,
          content: value,
          type: "latex",
        });

        // Add the right part only if it's not empty
        if (right) {
          newTokens.push({
            id: tokenIndex + newTokens.length,
            content: right,
            type: "text",
          });
        }

        // Shift all the remaining tokens by the number of tokens added
        const shiftedTokens = tokens
          .slice(tokenIndex + 1)
          .map((token, index) => ({
            ...token,
            id: tokenIndex + newTokens.length + index,
          }));

        // Replace the token at tokenIndex with the newTokens and add the shifted tokens
        updatedTokens = [
          ...tokens.slice(0, tokenIndex),
          ...newTokens,
          ...shiftedTokens,
        ];
      }
    }

    updatedTokens = updatedTokens.filter((token) => token.content !== "");

    if (updatedTokens[updatedTokens.length - 1].type === "latex") {
      updatedTokens.push({
        id: updatedTokens.length,
        content: "",
        type: "text",
      });
    }

    // Update the IDs of the remaining tokens to be consecutive
    updatedTokens = updatedTokens.map((token, index) => ({
      ...token,
      id: index,
    }));

    // Update the tokens array
    setTokens(updatedTokens);

    const removeDuplicates = (tokens) => {
      const seen = new Set();
      return tokens.filter((token) => {
        const key = JSON.stringify(token); // Use JSON.stringify to handle complex objects
        return seen.has(key) ? false : seen.add(key);
      });
    };

    // Update historyTokens to include the new tokens
    setHistoryTokens((prevTokens) => {
      // Add the new tokens to the history
      const newHistory = [
        ...prevTokens.slice(0, historyIndex + 1), // Keep tokens up to the current historyIndex
        updatedTokens, // Add the new tokens
      ];

      // Remove duplicates while preserving the most recent state
      return removeDuplicates(newHistory);
    });

    setIsModalOpen(false);
  };

  const handleSave = () => {
    if (value !== "") {
      saveLatex(`${display}${value}${display}`, tokenId, left, right);
    } else {
      setIsModalOpen(false);
    }
  };

  const options = [
    { value: '$', label: 'Inline' },
    { value: '$$', label: 'Block' },
  ];
  return (
    <>
      <div className="fixed inset-0 bg-black opacity-50 z-30 text-sm"></div>
      <div className="px-8 py-2 bg-gray-100 mt-6 z-40 fixed w-3/4 md:w-1/2 rounded-lg shadow-md shadow-slate-300 top-5 left-1/2 transform -translate-x-1/2">

        <div className="mt-5">
          <p className="">Expression</p>
          <math-field
            style={{ display: "block", height: "auto" }}
            onInput={(e) => setValue(e.target.value)}
          >
            {value}
          </math-field>

          <p className="mt-4">Latex</p>
          <textarea
            value={value}
            className="w-full h-14 p-2"
            onChange={(e) => setValue(e.target.value)}
          ></textarea>

          <p className="mt-4">Display</p>

          {/* <Select
            className="w-full bg-white h-10 border-none text-sm focus:border-2"
            value={display}
            displayEmpty
            onChange={(e) => setDisplay(e.target.value)}
          >
            <MenuItem value={"$"}>Inline</MenuItem>
            <MenuItem value={"$$"}>Block</MenuItem>
          </Select> */}
          <Select
            className="basic-single"
            classNamePrefix="select"
            value={options.find((option) => option.value === display)}
            onChange={(selectedOption) => setDisplay(selectedOption?.value)}
            options={options}
            isSearchable={false} // Disable searching if not needed
            isClearable={false}  // Disable clearing if not needed
            styles={customStyles}

          />

          <div className="mt-3 flex flex-row justify-end">
            <Button
              className="bg-white mr-4"
              onClick={() => setIsModalOpen(false)}
            >
              Cancel
            </Button>
            <Button className="bg-primary" onClick={handleSave}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
