
export default function FeatureItem({ title, text }) {
    return (
        <div className="flex-row items-center">
            <div className="flex-col gap-1">
                <p className="text-black font-bold text-lg afacad-flux-normal">
                        {title} 
                    </p>
                <span className="text-gray-600 text-md afacad-flux-normal">
                    {text}
                </span>
            </div>
        </div>
    )
}
