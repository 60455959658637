import "./RubricTab.css";
import { useCallback, useContext, useEffect, useState } from "react";
import Title from "src/components/content/Title";
import RubricContent from "src/pages/goodpoint/assessment/tabs/rubric/RubricContent";
import useCallApi from "src/api/useCallApi";
import { protectedResources } from "src/auth/AuthConfig";
import FileUploader from "src/components/ui/FileUploader";
import {
    LogType,
    RubricType,
    StepError,
    StepState,
} from "src/api/goodpoint/Const";
import LogProgress from "src/components/ui/LogProgress";
import { AssessmentContext } from "src/pages/goodpoint/assessment/AssessmentContext";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import { capitalize } from "src/util/StringUtil";
import DownloadButton from "src/components/ui/DownloadButton";
import { LogUpdateContext } from "src/pages/goodpoint/assessment/LogUpdateContext";

function DownloadRubricButton({ assessmentId, type = "enhanced", ...props }) {
    return (
        <DownloadButton
            downloadEndpoint={GoodPointApi.Rubric.Download(assessmentId, type)}
            filename={`${assessmentId}_rubric_${type}.pdf`}
            text={`Download ${capitalize(type)} Rubric`}
            bgColour={
                type === "enhanced" ? "var(--orange-mid)" : "var(--yellow-light)"
            }
            {...props}
        />
    );
}

function RubricTab() {
    const { assessmentObject, updateAssessmentObject, setFooterControls } =
        useContext(AssessmentContext);
    const callApi = useCallApi({
        msalRequest: { scopes: protectedResources.apiGoodPoint.scopes.write },
    });

    const [logUpdate, setLogUpdate] = useState([
        {
            message: "Starting...",
            type: LogType.INFO.toUpperCase(),
            progress: "NULL",
        },
    ]);
    const [isFinished, setIsFinished] = useState(
        assessmentObject.rubric_step !== StepState.PROCESSING
    );
    const [processingError, setProcessingError] = useState(null);

    const [rubricType, setRubricType] = useState(RubricType.ENHANCED);
    // const [rubric, setRubric] = useState(null);
    // const [editMode, setEditMode ] = useState(EDIT_MODES.DEFAULT)
    // const assessmentId = assessmentObject.id;

    useEffect(() => {
        if (assessmentObject.rubric_step !== StepState.COMPLETED) {
            setFooterControls(null);
        } else {
            setFooterControls([
                <DownloadRubricButton
                    key={0}
                    assessmentId={assessmentObject.id}
                    type="enhanced"
                />,
                <DownloadRubricButton
                    key={1}
                    assessmentId={assessmentObject.id}
                    type="ocr"
                />,
                <DownloadRubricButton
                    key={2}
                    assessmentId={assessmentObject.id}
                    type="raw"
                />,
            ]);
        }
    }, [assessmentObject.id, assessmentObject.rubric_step, setFooterControls]);

    useEffect(() => {
        if (assessmentObject.rubric_step !== StepState.READY) return;

        setIsFinished(false);
        callApi("POST", GoodPointApi.Rubric.Process(assessmentObject.id))?.then(
            (response) => {
                if (response.status === 200) {
                    updateAssessmentObject({ rubric_step: StepState.PROCESSING });
                } else {
                    response.json().then((body) => {
                        if (
                            !("error" in body && body["error"] === "Invalid request order")
                        ) {
                            updateAssessmentObject({ rubric_step: StepState.AVAILABLE });
                        }
                    });
                }
            }
        );
    }, [callApi, assessmentObject, updateAssessmentObject]);

    const getLogs = useCallback(() => {
        callApi("GET", GoodPointApi.Rubric.Logs(assessmentObject.id))?.then(
            (response) => {
                if (response.status === 200) {
                    response.json().then((body) => {
                        const logs = body["logs"];
                        setLogUpdate(logs);

                        if (body["finished"]) {
                            console.log("finished");
                            setIsFinished(true);
                        }

                        for (let i = 0; i < logs.length; i++) {
                            const log = logs[i];
                            console.log(log);
                            if (log.type.toLowerCase() === LogType.ERROR.toLowerCase()) {
                                updateAssessmentObject({
                                    rubric_step: StepState.READY,
                                    student_answers_step: StepState.NOT_AVAILABLE,
                                });
                                break;
                            } else if (
                                log.type.toLowerCase() === LogType.SUCCESS.toLowerCase()
                            ) {
                                updateAssessmentObject({
                                    rubric_step: StepState.COMPLETED,
                                    student_answers_step: StepState.AVAILABLE,
                                });
                            } else if (
                                log.type.toLowerCase() === LogType.FAIL.toLowerCase()
                            ) {
                                if (log.message.toLowerCase().includes("not enough credits")) {
                                    setProcessingError(StepError.INSUFFICIENT_CREDITS);
                                }
                                updateAssessmentObject({
                                    rubric_step: StepState.AVAILABLE,
                                    student_answers_step: StepState.NOT_AVAILABLE,
                                });
                                break;
                            }
                        }
                    });
                }
            }
        );
    }, [assessmentObject.id, callApi, updateAssessmentObject]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (!isFinished) {
                getLogs();
            } else {
                clearInterval(intervalId);
            }
        }, 4000);

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [getLogs, isFinished]);

    const content = (assessmentObject) => {
        switch (assessmentObject.rubric_step) {
            case StepState.AVAILABLE:
                return processingError ? (
                    <p>Insufficient funds to process rubric.</p>
                ) : (
                    <FileUploader
                        title="Upload Rubric"
                        text=".pdf"
                        uploadEndpoint={GoodPointApi.Rubric.Upload(assessmentObject.id)}
                        onUpload={() => {
                            updateAssessmentObject({ rubric_step: StepState.READY });
                        }}
                        formDataKey="rubric"
                    />
                );
            case StepState.PROCESSING:
                return <LogProgress text={"Processing rubric..."} />;
            case StepState.COMPLETED:
                return <RubricContent type={rubricType} />;
            default:
                return null;
        }
    };

    return (
        <div className="rubric-tab">
            <Title
                Size="h2"
                title="Rubric"
                subtitle={
                    assessmentObject.rubric_step === StepState.COMPLETED
                        ? `Overview of the ${
                                rubricType === RubricType.ENHANCED ? "AI-Enhanced" : "original"
                            } Rubric`
                        : `Upload the Rubric to get started`
                }
            />
            {assessmentObject.rubric_step === StepState.COMPLETED && (
                <div className="rubric-type-select flex-row gap-large">
                    <button
                        className={
                            `rubric-type-enhanced clickable border-mid rounded-small ` +
                            `${
                                rubricType === RubricType.ENHANCED
                                    ? "bg-orange-mid font-weight-bold"
                                    : "bg-yellow-light"
                            }`
                        }
                        onClick={() => setRubricType(RubricType.ENHANCED)}
                    >
                        {rubricType === RubricType.ENHANCED ? (
                            <b>Enhanced with AI</b>
                        ) : (
                            <p>Enhanced with AI</p>
                        )}
                    </button>
                    <button
                        className={
                            `rubric-type-original clickable border-mid rounded-small ` +
                            `${
                                rubricType === RubricType.ORIGINAL
                                    ? "bg-orange-mid font-weight-bold"
                                    : "bg-yellow-light"
                            }`
                        }
                        onClick={() => setRubricType(RubricType.ORIGINAL)}
                    >
                        {rubricType === RubricType.ORIGINAL ? (
                            <b>Original</b>
                        ) : (
                            <p>Original</p>
                        )}
                    </button>
                </div>
            )}
            <LogUpdateContext.Provider value={{ logUpdate, setLogUpdate }}>
                {content(assessmentObject)}
            </LogUpdateContext.Provider>
        </div>
    );
}

export default RubricTab;
