import { getFromLocalStorage, saveToLocalStorage, StorageKeys } from "src/storage/LocalStorage";
// import GoodPointApi from "src/api/goodpoint/GoodPointApi";

export function getRubrics() {
    return getFromLocalStorage(StorageKeys.RUBRICS, {});
}

export function setRubrics(rubrics) {
    return saveToLocalStorage(StorageKeys.RUBRICS, rubrics);
}

// export function getRubricById(assessmentId, enhanced=false) {
//     const rubric = getRubrics()[assessmentId];
//     return rubric ? {
//         rubric: rubric[enhanced ? "enhanced" : "original"],
//         rubricUrl: rubric["rubricUrl"]
//     } : undefined;
// }

export function getRubricById(assessmentId, enhanced=false){
    const rubrics = getRubrics();
    const rubricData = rubrics[assessmentId];
    if (!rubricData) return undefined;

    // const currentTime = new Date().getTime();
    // const expiryTime = rubricData["expiryTime"];

    // if ((expiryTime && currentTime > expiryTime) || !rubricData["rubricUrl"]) {
    //     fetch(GoodPointApi.Rubric.All(assessmentId, true))
    //         .then(response => response.json())
    //         .then(data => {
    //             saveRubric(assessmentId, {
    //                 rubric: data.rubric,
    //                 rubricUrl: data.rubric_url,
    //             }, true);

    //         })
    //         .catch(error => console.error('Failed to fetch rubric:', error));
    // }    

    return {
        rubric: rubricData[enhanced ? "enhanced" : "original"],
        rubricUrl: rubricData["rubricUrl"]
    };
}


export function saveRubric(assessmentId, data, enhanced = false) {
    const currentTime = new Date();
    const expiryTime = new Date(currentTime.getTime() + 48 * 60 * 60 * 1000); 

    const newRubrics = getRubrics();
    const key = enhanced ? "enhanced" : "original";
    if (!data){
        return;
    }
    //race conditions ate my ass 

    if (!newRubrics[assessmentId]) {
        newRubrics[assessmentId] = {};
    }

    if (data.rubric){
        newRubrics[assessmentId][key] = data.rubric;
    }

    if (data.rubricUrl){
        newRubrics[assessmentId]["rubricUrl"] = data.rubricUrl;
        newRubrics[assessmentId]["expiryTime"] = expiryTime.getTime();
    }
    
    return setRubrics(newRubrics);
}

