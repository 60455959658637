import { createContext } from "react";

//EditToolbar
export const EDIT_MODES = {
  DEFAULT: "default",
  REORDER: "reorder",
  DELETE: "delete",
};

export const AssessmentContext = createContext({
  assessmentObject: null,
  updateAssessmentObject: (_a) => {},
  footerControls: null,
  setFooterControls: (_fc) => {},

  //EditToolbar
  editMode: EDIT_MODES.DEFAULT,
  setEditMode: (_e) => {},

  //delete checkboxes
  deleteSelected: null,
  setDeleteSelected: (_ds) => {},

  //rubric
  rubric: null,
  setRubric: (_r) => {},
  rubricUrl: null,
  setRubricUrl: (_url) => {},
  rubricUpdates: new Set(),
  addRubricUpdate: (_ru) => {},

  //question feedback
  questionFeedback: null,
  setQuestionFeedback: (_q) => {},

  //student feedback
  studentFeedback: null,
  setStudentFeedback: (_sf) => {},

  //class feedback
  classFeedback: null,
  setClassFeedback: (_cs) => {},

  //studnet answers
  students: null,
  setStudents: (_ss) => {},
  selectedStudent: null,
  setSelectedStudent: (_s) => {},

  //pdf preview
  showPDF: null,
  setShowPDF: (_df) => {},
  rightPDF: null,
  setRightPDF: (_df) => {},
  file: null,
  setFile: (_f) => {},

  //current Tab
  currentTab: null,
});
