import { Search, Xmark } from "iconoir-react";
import { useEffect, useRef, useState } from "react";

import "./SearchBar.css";


export default function SearchBar({ onChangeSearchText, clearRef = null, onClear = null, className="" }) {
    const inputRef = useRef(null);
    const [searchText, setSearchText] = useState(null);
    const [changed, setChanged] = useState(false);

    function onInputTextChange(textContent) {
        setChanged(true);
        if (textContent.length === 0) {
            setSearchText(null);
            clearInputText();
        } else {
            setSearchText(textContent);
        }
    }

    function clearInputText() {
        setChanged(true);
        setSearchText(null);
        inputRef.current.value = "";
        onClear && onClear();
    }

    clearRef.current = clearInputText;

    useEffect(() => {
        if (!changed) return;
        onChangeSearchText(searchText ?? "");
        setChanged(false);
    }, [changed, onChangeSearchText, searchText]);

    const baseClass = "rubric-search shadow-md shadow-gray-200 flex-row gap-small align-center cursor-text border-t-gray-200 border-t-2 pl-2.5 rounded-lg hover:border-2 hover:border-[var(--orange-dark)]";

    return <div
        className={`${baseClass} ${className} `} 
        onClick={() => { inputRef.current.focus(); }}
    >
        <Search />
        <input
            ref={inputRef}
            placeholder="Search..."
            className=" focus:outline-none text-black"
            onChange={(e) => onInputTextChange(e.target.value)}
        />
        <div className={`ml-auto mr-2 rubric-search-clear ${searchText ? "cursor-pointer" : ""}`}
             onClick={clearInputText}>
            <Xmark strokeWidth={searchText ? 1.5 : 0} />
        </div>
    </div>
}