import { replacer, reviver } from "src/util/Json";
const CURRENT_VERSION = "3.0";

function checkDataVersion() {
  const version = localStorage.getItem("data-version");
  if (version !== CURRENT_VERSION) {
    localStorage.setItem("data-version", CURRENT_VERSION);
    return false;
  }
  return true;
}

export const StorageKeys = {
  ASSESSMENTS: "assessment-descs",
  ASSESSMENT_OBJECTS: "assessment-objects",
  ASSESSMENT_TABS: "assessment-tabs",
  RUBRICS: "rubrics",
  STUDENT_ANSWERS: "student-answers",
  STUDENT_LIST: "students-list",
  GRADING_RESULTS: "grading-results",
};

export function getFromLocalStorage(storageKey, defaultValue = null) {
  if (checkDataVersion()) {
    const value = localStorage.getItem(storageKey);
    return value ? JSON.parse(value, reviver) : defaultValue;
  } else {
    return defaultValue;
  }
}

export function saveToLocalStorage(storageKey, value) {
  if (checkDataVersion()) {
    try {
      localStorage.setItem(storageKey, JSON.stringify(value, replacer));
      return value;
    } catch (error) {
      return;
    }
  } else {
    return value;
  }
}

// export const StorageKeys = ({
//     ASSESSMENTS: "assessment-descs",
//     ASSESSMENT_OBJECTS: "assessment-objects",
//     ASSESSMENT_TABS: "assessment-tabs",
//     RUBRICS: "rubrics",
//     STUDENT_ANSWERS: "student-answers",
//     STUDENT_LIST: "students-list",
//     GRADING_RESULTS: "grading-results"
// });

// export function getFromLocalStorage(storageKey, defaultValue=null) {
//     return defaultValue;
// }

// export function saveToLocalStorage(storageKey, value) {
//     return value;
// }
// }
