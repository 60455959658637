import { capitalize } from "src/util/StringUtil"
import { NavArrowRight, NavArrowLeft, InfoCircle, GraduationCap, StatsUpSquare, Page } from "iconoir-react";
import "./Tabs.css"
import { Tab, TabState } from "src/api/goodpoint/Const";
import IconButton from "src/components/ui/IconButton";
import { useHasRole } from "src/api/useGetRole";

export const TabIcons = ({
    [Tab.INFO]: InfoCircle,
    [Tab.RUBRIC]: Page,
    [Tab.ANSWERS]: GraduationCap,
    [Tab.GRADING]: StatsUpSquare
});

const TabStyles = ({
    [TabState.COMPLETE]: {
        bgColour: "var(--yellow-light)",
    },
    [TabState.CURRENT]: {
        bgColour: "var(--orange)",
        fontWeight: "bold"
    },
    [TabState.NEXT]: {
        fgColour: "var(--orange-dark)",
        bgColour: "var(--yellow-light)",
    },
    [TabState.INCOMPLETE]: {
        enabled: false
    }
});

function TabButton({ tab, tabState, changeTab }) {
    const disabled = useHasRole("student");
    return <IconButton
        key={tab}
        text={capitalize(tab)}
        fontSize="large"
        Icon={TabIcons[tab]}
        iconSize={25}
        iconLeft={false}
        justifyContent="space-between"
        borderSize="var(--border-thin)"
        className={`fill-width outline-thin ${tabState === TabState.CURRENT ? "outline-black" : "outline-transparent"}`}
        // Disable changing tabs for students
        enabled={!disabled || tab === Tab.GRADING || tab === Tab.RUBRIC}
        onClick={() => {if (!disabled || tab === Tab.GRADING || tab === Tab.RUBRIC) changeTab(tab)}}
        {...TabStyles[tabState]}
    />
}

function NavTabItem({ tab, rightNav, changeTab }) {
    if (tab) {
        return <button 
            className={`tab-button clickable nav-button ${rightNav ? "nav-right" : "nav-left"} border-mid rounded-small`} 
            onClick={() => changeTab(tab)}
        >
            {
                rightNav ? <NavArrowRight /> : <NavArrowLeft />
            }
        </button>
    }
    return <></>;
}

export function AssessmentTabs({tabsState, changeTab, currentTab}) {
    const rightTab = Object.keys(tabsState)[Object.keys(tabsState).indexOf(currentTab) + 1];
    const leftTab = Object.keys(tabsState)[Object.keys(tabsState).indexOf(currentTab) - 1];
    return (
        <div className="assessment-tabs flex-row gap-large">
            <NavTabItem tab={leftTab} rightNav={false} changeTab={changeTab}/>
                { 
                    Object.entries(tabsState).map(([tab, tabState]) => { return (
                        <TabButton key={tab} tab={tab} tabState={tabState} changeTab={changeTab}/>
                    ) })
                }
            <NavTabItem tab={rightTab} rightNav={true} changeTab={changeTab}/>
        </div>
    )
}