import "./GradingContent.css"
import { useFetchGeneralResults } from "src/api/goodpoint/useFetchResults";
import { Group, MultiplePages } from "iconoir-react";
import { GradingType, StepState } from "src/api/goodpoint/Const";
import { useContext, useMemo, useState } from "react";
import { AssessmentContext } from "src/pages/goodpoint/assessment/AssessmentContext";
import ClassPerformance from "src/pages/goodpoint/assessment/tabs/grading/class-performance/ClassPerformance";
import StudentPerformance from "src/pages/goodpoint/assessment/tabs/grading/student-performance/StudentPerformance";
import Title from "src/components/content/Title";


function TypeSelectButton({ gradingType, Icon, text, selectedGradingType, setSelectedGradingType }) {
    return (
        <button
            className={
                `grading-type-button flex-row justify-content-center gap-small clickable border-thin rounded-small outline-thin ` +
                `${(selectedGradingType === gradingType) ? "bg-orange-mid font-weight-bold" : "bg-yellow-light outline-transparent"}`
            }
            onClick={() => setSelectedGradingType(gradingType)}
        >
            <Icon strokeWidth={1.5}/>
            {
                (selectedGradingType === GradingType.CLASS) ? <b>{text}</b> : <p>{text}</p>
            }
        </button>
    )
}

export default function GradingContent() {
    const { assessmentObject } = useContext(AssessmentContext);

    const [gradingType, setGradingType] = useState(GradingType.CLASS);
    
    const fetchGeneralResults = useFetchGeneralResults(assessmentObject.id);
    
    const examResults = useMemo(() => {
        const result = fetchGeneralResults();
        return result;
    }, [fetchGeneralResults]);

    return (
        <div className="grading-content">
            <Title
                Size="h2"
                title="Grading"
                subtitle={
                    assessmentObject.grading_step === StepState.COMPLETED 
                        ? `Review your ${(gradingType === GradingType.CLASS) ? "class'" : "students'"} performance`
                        : `Begin the AI Grading process`
                }
            />
            <div className='grading-type-select gap-large fill-width'>
                <TypeSelectButton 
                    gradingType={GradingType.CLASS} Icon={MultiplePages} text={"Class Performance"}
                    selectedGradingType={gradingType} setSelectedGradingType={setGradingType}
                />
                <TypeSelectButton 
                    gradingType={GradingType.STUDENTS} Icon={Group} text={"Student Performance"}
                    selectedGradingType={gradingType} setSelectedGradingType={setGradingType}
                />
            </div>
            {
                gradingType === GradingType.CLASS
                    ? <ClassPerformance examResults={examResults} />
                    : <StudentPerformance examResults={examResults} />
            }
        </div>
    )
}